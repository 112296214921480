<template>
	<div id="Payment" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>결제하기</h2>
			</div>
			<div class="boxwrap">
				<h6>주문자</h6>
				<table>
					<tbody>
						<tr>
							<th>이름 <span style="color : red">*</span></th>
							<td><input type="text" v-model='orderer.name' placeholder="주문자 이름"></td>
						</tr>
						<tr>
							<th>연락처 <span style="color : red">*</span></th>
							<td><input type="text" v-model='orderer.phone' placeholder="주문자 연락처 (ex : 010-0000-0000)"></td>
						</tr>
						<tr>
							<th>이메일 <span style="color : red">*</span></th>
							<td><input type="text" v-model='orderer.email' placeholder="주문자 이메일"></td>
						</tr>
					</tbody>
				</table>
				<p class="notice">해당 이메일로 영수증과 주문내역서를 추가로 보내드립니다.</p>
			</div>
			<div class="boxwrap">
				<div class="d-flex">
					<h6>배송정보</h6>
					<b-form-checkbox v-model="sameInfo">주문자와 동일</b-form-checkbox>
				</div>
				<table>
					<tbody>
						<tr>
							<th>수령인 <span style="color : red">*</span></th>
							<td><input type="text" v-model='deliveryInfo.name' placeholder="수령인 이름"></td>
						</tr>
						<tr>
							<th>주소 <span style="color : red">*</span></th>
							<td><input type="text" v-model='deliveryInfo.address' readonly @click="openAddress" placeholder="주소 (클릭시 주소 검색)"></td>
						</tr>
						<tr>
							<th></th>
							<td><input ref="address_detail" type="text" :disabled="deliveryInfo.postcode === ''" placeholder="상세 주소 (동, 층, 호)"></td>
						</tr>
						<tr>
							<th>배송요청 사항</th>
							<td><input type="text" v-model='deliveryInfo.comment' placeholder="배송시 요청사항 (ex : 부재시 문앞)"></td>
						</tr>
						<tr>
							<th>연락처 <span style="color : red">*</span></th>
							<td><input type="text" v-model='deliveryInfo.phone' placeholder="수령인 연락처"></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="boxwrap">
				<h6>주문상품</h6>
				<ul>
					<li v-for="item in cartList" :key="item._id">
						<div class="imgbox"><img :src="productData[item.id].thumbnail"></div>
						<div class="txtbox">
							<b>{{productData[item.id].name}}</b>
							<p>수량: {{item.count}}</p>
							<span>{{changeCommaPrice(productData[item.id].price * item.count)}}원</span>
						</div>
					</li>
				</ul>
			</div>
			<div class="boxwrap">
				<h6>주문금액</h6>
				<table>
					<tbody>
						<tr>
							<th>상품금액</th>
							<td>{{changeCommaPrice(this.orderPrice - this.deliveryPrice)}}원</td>
						</tr>
						<!-- <tr>
							<th>배송비</th>
							<td>{{changeCommaPrice(this.deliveryPrice)}}원</td>
						</tr> -->
						<!-- <tr>
							<th>할인금액</th>
							<td>-{{changeCommaPrice(orderInfo.discount)}}원</td>
						</tr> -->
						<tr>
							<th><b>총 금액</b></th>
							<td><h5>{{changeCommaPrice(this.orderPrice)}}원</h5></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="boxwrap">
				<h6>결제수단</h6>
				<b-form-checkbox button value="direct" v-model="payMethod">
					<p>온라인 결제</p>
					<span>KG Inisis 간편결제</span>
				</b-form-checkbox>
			</div>
			<div class="bottom">
				<div>
					<b-form-checkbox v-model="agree">상품 및 구매조건을 확인하였으며, 결제대행 서비스에 동의합니다. (필수)</b-form-checkbox>
					<ul>
						<li>개인정보 처리방침<button>보기</button></li>
						<li>서비스 이용 약관<button>보기</button></li>
						<!-- <li>유해화학물질 시약 판매 관련 안내<button>보기</button></li> -->
					</ul>
				</div>
				<button class="payBtn" @click="payment">결제하기</button>
			</div>
		</div>
		<div style="display : none">
			<form id="SendPayForm_id"                       name=""             method="POST"  accept-chartset="UTF-8">
	            <input                                      name="version"      value="1.0" >
	            <input                                      name="mid"          :value="inipayData.mid" >
	            <input ng-model="oid"                       name="oid"          :value="orderId" >
	            <input                                      name="goodname"     :value="'고대 교우캐릭터 공프공 컬러 12구set'" >
	            <input ng-model="price"                     name="price"        :value="orderPrice" >
	            <input                                      name="currency"     value="WON" >
	            <input                                      name="buyername"    :value="orderer.name" >
	            <input                                      name="buyertel"     :value="orderer.phone" >
	            <input                                      name="buyeremail"   :value="orderer.email" >
	            <input type="text"                          name="timestamp"    :value="inipayData.timestamp" >
	            <input type="hidden"                        name="signature"    :value="inipayData.signature" >
	            <input                                      name="returnUrl"    value="https://hwatong.or.kr/payment/card/return" >
	            <input type="hidden"                        name="mKey"         :value="inipayData.mKey" >
	            <input type="hidden"                        name="gopaymethod"         :value="`Card`" >
	            <input type="hidden"                        name="payViewType"         :value="`popup`" >
	            <input type="hidden"                        name="popupUrl"         value="https://hwatong.or.kr/payment/popup" >
	        </form>
		</div>

		<b-modal ref="addressModal" title="주소 검색" hide-footer no-close-on-backdrop>
		    <vue-daum-postcode @complete="setAddress"/>
		</b-modal>
	</div>
</template>

<script>
import store from '@/assets/js/store.js'
import cart from '@/assets/js/cart.js'
import api from '@/api/board.js'

export default {
	mixins: [store, cart],
	async created() {
		const count = Number(this.$route.query.count);
		if(isNaN(count) || count < 1) {
			this.count = 0
		} else {
			this.count = count;
		}

		const my = await api.getMy();
		if(my.data.data) {
			this.orderer.name = my.data.data.name;

		}

		this.initList();

		window.moveToComplete = (url) => {
			location.href = url
		}
	},
	data() {
		return {
			count : 1,
			price : 45000,
			deliveryPrice : 0,
			orderId : '',
			orderer : {
				name : '',
				phone : '',
				email : ''
			},
			deliveryInfo : {
				name : '',
				postcode : '',
				address : '',
				address_detail : '',
				comment : '',
				phone : ''
			},
			inipayData : {
				timestamp : '',
				signature : '',
				returnUrl : '',
				mKey : '',
				popupUrl : '',
			},
			sameInfo : false,
			payMethod : 'direct',
			agree : true,
			productData : {
				'1' : {
					id : '',
					name : '',
					price : 0,
					thumbnail : '',
					description : ''
				},
				'2' : {
					id : '',
					name : '',
					price : 0,
					thumbnail : '',
					description : ''
				}
			}
		}
	},
	watch : {
		'sameInfo'() {
			if(this.sameInfo) {
				if(this.orderer.name !== this.deliveryInfo.name) {
					this.deliveryInfo.name = this.orderer.name;
				}

				if(this.orderer.phone !== this.deliveryInfo.phone) {
					this.deliveryInfo.phone = this.orderer.phone;
				}
			}
		},
		'orderer.name'() {
			if(this.sameInfo) {
				if(this.orderer.name !== this.deliveryInfo.name) {
					this.deliveryInfo.name = this.orderer.name;
				}
			}
		},
		'orderer.phone'() {
			if(this.sameInfo) {
				if(this.orderer.phone !== this.deliveryInfo.phone) {
					this.deliveryInfo.phone = this.orderer.phone;
				}
			}
		},
		'deliveryInfo.name'() {
			if(this.sameInfo) {
				if(this.deliveryInfo.name !== this.orderer.name) {
					this.orderer.name = this.deliveryInfo.name;
				}
			}
		},
		'deliveryInfo.phone'() {
			if(this.sameInfo) {
				if(this.deliveryInfo.phone !== this.orderer.phone) {
					this.orderer.phone = this.deliveryInfo.phone;
				}
			}
		}
	},
	computed : {
		orderPrice() {
			let price = 0;

			this.cartList.forEach(item => {
				price += this.productData[item.id].price * item.count;
			})

			return price + this.deliveryPrice;
		}
	},	
	methods: {
		openAddress(){
			this.$refs['addressModal'].show();
		},
		setAddress(data) {
			this.deliveryInfo.postcode = data.zonecode;
			this.deliveryInfo.address = data.roadAddress;
			this.$refs['addressModal'].hide();
			setTimeout(() => {
				this.$refs['address_detail'].focus();
			}, 300)
		},
		async getPaymentInformation() {
			this.orderId = 'KORUNI_' + (new Date().getTime());
            const form = {
            	orderId : this.orderId, 
            	name : this.orderer.name, 
            	phone : this.orderer.phone, 
            	email : this.orderer.email, 
            	price : this.orderPrice,
            	deliveryInfo : this.deliveryInfo,
            	orderer: this.orderer,
            	count : this.count
            }
            const {data: {data}} = await api.getPaymentInicisCard(form);
            Object.keys(data).forEach(k => this.inipayData[k] = data[k]);
            
            window.INIStdPay.pay('SendPayForm_id');
        },
        async payment() {
	        if(this.orderer.name === '') {
	        	return alert("주문자 이름을 입력해주세요.")
	        }
			if(this.orderer.phone === '') {
				return alert("주문자 연락처를 입력해주세요.")
			}
			if(this.orderer.email === '') {
				return alert("주문자 이메일을 입력해주세요.")
			}
			if(this.deliveryInfo.name === '') {
				return alert("수령인 이름을 입력해주세요.")
			}
			if(this.deliveryInfo.address === '') {
				return alert("배송 주소를 입력해주세요.")
			}
			if(this.deliveryInfo.phone === '') {
				return alert("수령인 연락처를 입력해주세요.")
			}
        	await this.getPaymentInformation();
        },
		async initList(){
			this.cartList.forEach(async (item) => {
				let {data} = await api.getProductDetail(item.id);
				data = data.data;
				
				this.productData[item.id] = data;
			})
		}
	}
}
</script>

<style lang="scss">
#Payment {
	.boxwrap {width:100%; margin-top:50px;
		&:nth-child(3) {
			h6 {border-bottom:0;}
			table {
				tr {
					&:nth-child(1) {border-top:1px solid #bbb;}
				}
			}
		}
		&:nth-child(6) {
			h6 {border-bottom:0;}
			table {
				tr {
					th {font-size:1rem;}
					td {font-size:1rem;}
				}
			}
		}
		h6 {font-size:1rem; color:#000; font-weight:bold; width:100%; text-align: left; border-bottom:1px solid #bbb; padding-bottom:20px; margin-bottom:0;}
		table { margin-top:0; padding-top:0; width:100%;
			tr {
				th {background:#f9f9f9; font-size:0.875rem; color:#111; width:150px; font-weight:500; padding:12px; vertical-align:middle;}
				td {width:calc(100% - 150px); padding:4px 16px;
					input {width:100%; border-radius:5px; border:1px solid #ccc;}
					h5 {font-size:1.5rem; color:#ff5746; font-weight:bold;}
				}
			}
		}
		ul {
			li {width:100%; padding:20px; display:flex; height: auto; align-items: center;
				.imgbox {width:120px; height:120px; border:1px solid #eee; overflow:hidden;
					img {width:inherit; height: inherit; object-fit: cover;}
				}
				.txtbox { padding-left:20px;
					b {font-size:0.875rem; font-weight: 500;}
					p {margin-top:6px; font-weight:500; font-size:0.875rem}
					span {display: block; font-size: 0.875rem;}
				}
			}
		}
		p.notice {margin-top:14px; font-size:0.75rem;}
		.d-flex { height: auto; 
			.custom-checkbox { text-align:right; width:200px;
				input[type="checkbox"] { position:absolute; width:1px; height:1px; padding:0; margin:-1px; overflow:hidden; clip:rect(0,0,0,0); border:0; 
					+ label {display:inline-block; position:relative; padding-left:30px; cursor:pointer; font-size:0.875rem;
						&:before {content:''; position:absolute; left:0; top:-3px; width:26px; height:26px; text-align:center; background:#fff; border:0; box-sizing:border-box; border-radius:20px; background-image:url("../../assets/image/check-icon-unchecked@3x.png"); background-repeat:no-repeat; background-size:26px;}
					}
					&:checked + label:after{ content: ''; position:absolute; top:-3px; left:0; width:26px; height:26px; border-radius:20px; background-color:#8b0028; background-image:url("../../assets/image/check-icon-checked@3x.png"); background-repeat:no-repeat; background-size:26px;}
				}

			}
		}
		.btn-group-toggle { 
			.btn { box-sizing:border-box; width:388px; padding:15px; text-align: center; border:2px solid #ccc; color:#333; background:#fff;
				&.active {background:rgba(139,0,40,0.1); border:2px solid #8b0028;}
				p {margin-top:-14px; font-size:1rem; font-weight:bold; padding-bottom:0px}
				span {font-size:0.75rem;}
			}
			input[type="checkbox"] {visibility: hidden; clip:rect(0, 0, 0, 0); width:1px; height:1px;}
		}
	}
	.bottom {width:100%; text-align:center; margin-top:100px; 
		> div {width:50%; text-align: left; margin:0 auto 80px; 
			.custom-checkbox { 
				input[type="checkbox"] { position:absolute; width:1px; height:1px; padding:0; margin:-1px; overflow:hidden; clip:rect(0,0,0,0); border:0; 
					+ label {display:inline-block; position:relative; padding-left:30px; cursor:pointer; font-size:1rem;
						&:before {content:''; position:absolute; left:0; top:-3px; width:26px; height:26px; text-align:center; background:#fff; border:0; box-sizing:border-box; border-radius:20px; background-image:url("../../assets/image/check-icon-unchecked@3x.png"); background-repeat:no-repeat; background-size:26px;}
					}
					&:checked + label:after{ content: ''; position:absolute; top:-3px; left:0; width:26px; height:26px; border-radius:20px; background-color:#8b0028; background-image:url("../../assets/image/check-icon-checked@3x.png"); background-repeat:no-repeat; background-size:26px;}
				}

			}
			ul { margin-top:10px;
				li {font-size:0.875rem; color:#666; 
					button {margin-left:2px; text-decoration:underline;}
				}
			}
		}
		button.payBtn {width:300px; background:#8b0028; color:#fff; border-radius:10px; font-size:1.25rem; padding:18px 0;}
	}
}	
</style>