<template>
	<div id="BoardDetail" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>{{matchBoard()}}</h2>
				<div class="btnwrap" v-if="admin">
					<router-link :to="{path: '/board/'+ $route.params.board +'/edit', query : { id: boardDetail.id, edit: 'edit'}}">편집</router-link>
					<button v-b-modal.removeBoard>삭제</button>
				</div>
			</div>
			<div class="content">
				<div class="title">
					<h4>{{boardDetail.title}}</h4>
					<p>
						<b>{{boardDetail.author}}</b>
						<span>{{formatDate(boardDetail.create_datetime)}}</span>
						<span>조회 {{boardDetail.hit}}</span>
					</p>
				</div>
				<div class="txtbox">
					<p v-html="boardDetail.content"></p>
					<div v-for="(file,index) in boardDetail.attachment" :key="index">
						<img v-if="file.filename.toLowerCase().slice(-3) == 'jpeg' || file.filename.toLowerCase().slice(-3) == 'jpg' || file.filename.toLowerCase().slice(-3) == 'png'" style="max-width: 70%; margin-top : 10px;" :src="file.url">
					</div>
				</div>
			</div>
			<div class="attach" v-if="boardDetail.attachment.length > 0">
				<h6>첨부파일</h6>
				<div class="box">
					<p v-for="file in boardDetail.attachment" :key="file.idx">
						<img v-if="file.filename.slice(-3) == 'pdf'" src="@/assets/image/icon-file-ppt@3x.png">
						<img v-else-if="file.filename.slice(-3) == 'jpg' || file.filename.slice(-3) == 'png'" src="@/assets/image/icon-file-jpg@3x.png">
						<a @click="fileDownload(file.url, file.filename)">{{file.filename}}</a>
					</p>
				</div>
			</div>
			<div class="bottom">
				<router-link :to="'/board/'+$route.params.board" class="listBtn">목록</router-link>
				<div class="ml-auto">
					<button class="prevBtn" @click="loadDetail(boardDetail.prev)" v-if="boardDetail.prev">이전 글</button>
					<button class="nextBtn" @click="loadDetail(boardDetail.next)" v-if="boardDetail.next">다음 글</button>
				</div>
			</div>
		</div>

		<b-modal id="removeBoard" hide-header hide-footer size="default" centered>
			<h3>게시글 삭제</h3>
			<div class="txtbox">
				<p>선택하신 게시물을 삭제하시겠습니까?</p>
				<div class="btnWrap">
					<button class="releaseNoticeBtn" @click="remove">게시글 삭제</button>
					<button @click="$bvModal.hide('removeBoard')">닫기</button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import store from '@/assets/js/store.js'
import board from '@/api/board'
import cookies from 'vue-cookies';

export default {
	mixins: [store],
	async created() {
		this.admin = cookies.isKey('TOKEN_KEY');
		await this.loadDetail(this.$route.params.id)

		console.log(this.admin)
	},
	data() {
		return {
			admin: true,
			boardDetail: {}
		}
	},
	watch: {
		'$route.params.id'() {
			this.loadDetail(this.$route.params.id);
		}
	},
	methods: {
		async loadDetail(id) {
			const response = await board.getDetail(id);

			this.boardDetail = response.data.data;

			console.log(this.boardDetail)
		},
		fileDownload(url, filename) {
	    	window.fileDownload(url, filename)
	    },
		formatDate(date) {
			if (date) {
				var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear() + '',
					hour = d.getHours() + '',
					minute = d.getMinutes() + ''
				if (month.length < 2) {
					month = '0' + month
				}
				if (day.length < 2) {
					day = '0' + day
				}
				if (hour.length < 2) {
					hour = '0' + hour
				}
				if (minute.length < 2) {
					minute = '0' + minute
				}
				return [year, month, day].join('-') + '\n' + [hour, minute].join(':')
			} else {
				return '-'
			}
		},
		async remove() {
			const id = this.boardDetail.id;
			await board.removeMulti(id);
			location.href = '/board/' + this.$route.params.board
		},
	}
}
</script>

<style lang="scss">
#BoardDetail {
	.titlebox {display:flex; height:auto; align-items: center; padding-bottom:15px;
		h2 {padding-bottom:0;}
		.btnwrap { margin-left:auto;
			a, button {display:inline-block; width:70px; border-radius:4px; background:#8b0028; color:#fff; font-size:0.875rem; font-weight:500; margin-left:10px; height:32px; padding:5px 0; text-align:center;}
		}
	}
	.content {width:100%; margin-top:0px; padding:30px; border:1px solid #eee;
		.title { padding-bottom:26px; border-bottom:1px solid #eee; width:100%;
			h4 {font-size:1.25rem; font-weight:bold; color:#000; letter-spacing:-0.67px;}
			p {padding-top:18px; font-size:0.875rem;
				b {font-weight: bold;}
				span {margin-left:20px; display:inline-block;}
			}
		}
		.txtbox {width:100%; font-size:0.875rem; font-weight:500; margin-top:36px;
			img {width:100%;}
		}
	}
	.bottom {width:100%; padding-top:15px; display:flex; height:auto; align-items:center;
		a {
			display:inline-block; width:70px; border-radius:4px; background:#8b0028; color:#fff; font-size:0.875rem; font-weight:500; height:32px; padding:5px 0; text-align:center;
		}
		button {display:inline-block; width:70px; border-radius:4px; background:#8b0028; color:#fff; font-size:0.875rem; font-weight:500; height:32px; padding:5px 0; text-align:center;}
		.ml-auto {margin-left:auto;
			button {display:inline-block; margin-left:10px; }
		}
	}

	.attach { margin-top:30px; margin-bottom:15px;
		h6 {font-size:1.3rem; color:rgba(0,0,0,0.87); font-weight:bold; margin-bottom:10px;}
		.box {width:100%; padding:15px; border:1px dashed #eee;
			p {font-size:0.875rem; color:rgba(0,0,0,0.87); margin-bottom:10px; font-weight:500; cursor:pointer;
				img {width:24px; margin-left:5px; margin-top:-5px;}
				&:last-child {margin:0;}
			}
		}
	}
}	
</style>