export default {
	data() {
		return {
			board: null,
			boardMenu: [
				{ name: '자유게시판', path: 'free' },
				{ name: '갤러리', path: 'gallery' },
				{ name: '교우회 알림판', path: 'alert'},
				{ name: '뉴스레터', path: 'newsletter' },
				{ name: '경조사', path: 'condolences' },
				// { name: '공지사항', path: 'notice' },
				// { name: '자료실', path: 'attach' },
			],
			shopMenu: [
				{ name: '화통회샵', path: '/shop' },
				{ name: '비회원 주문조회', path: '/nonMemberCart'}
			],
			// boardList: [
			// 	{ id: 1, title: '샘플텍스트', description: '샘플텍스트', date: '2020-01-01', count: 10, notice: true, },
			// 	{ id: 2, title: '제4회 화공생명공학과 교우회-재학생 멘토링 Day 안내', description: '제4회 화공생명공학과 교우회-재학생 멘토링 Day 안내'},
			// 	{ id: 3, title: '제2회 화공생명공학과 교우회 전문가 교우 초청 강연 안내(오후 6시로 시간변경)', description: '제2회 화공생명공학과 교우회 전문가 교우 초청 강연 안내(오후 6시로 시간변경)', date: '2020-01-01', count: 10 },
			// 	{ id: 4, title: '제15회 고려대학교 화공생명공학과 교우회정기 총회', description: '제15회 고려대학교 화공생명공학과 교우회정기 총회' }
			// ],
			boardDetail: {
				id: 1,
				title: '제4회 화공생명공학과 교우회-재학생 멘토링 Day 안내', 
				author: '운영자',
				date: '2021-04-12 13:04',
				count: '41',
				description: '화통회 홈페이지가 리뉴얼되었습니다!<br>감사합니다.'
			},
			galleryList: [
				{ id: 1, title: '고려대학교 화공생명공학과 교우회보(vol.2) 웹진 출간 안내', date: '2018-03-12', count: '41', image: 'item-thumnail@3x.png'},
				{ id: 2, title: '고려대학교 화공생명공학과 교우회보(vol.2) 웹진 출간 안내', date: '2018-03-12', count: '41', image: 'item-thumnail@3x.png'},
			],
			shoppingList: [
				{ _id: 1, image: 'item-thumnail@3x.png', name: '고대 교우캐릭터 공프공 컬러 12구set', count:'1', option: '기본선택', price: '45000', },
				// { _id: 2, image: 'item-thumnail@3x.png', name: '2019 고연전 단체티셔츠 - 할인', count:'1', option: '기본선택', price: '20000', },
				// { _id: 3, image: 'item-thumnail@3x.png', name: '고대 교우캐릭터 골프공 12구set', count:'1', option: '기본선택', price: '45000', },
				// { _id: 4, image: 'item-thumnail@3x.png', name: '고대 교우캐릭터 골프공 12구set', count:'1', option: '기본선택', price: '45000', },
				// { _id: 5, image: 'item-thumnail@3x.png', name: '고대 교우캐릭터 골프공 12구set', count:'1', option: '기본선택', price: '45000', },
				// { _id: 6, image: 'item-thumnail@3x.png', name: '고대 교우캐릭터 골프공 12구set', count:'1', option: '기본선택', price: '45000', },
			],
			paymentList: [
				{ _id: 1, image: 'item-thumnail@3x.png', name: '고대 교우캐릭터 공프공 컬러 12구set', count:'1', option: '기본선택', price: '45000', },
				// { _id: 2, image: 'item-thumnail@3x.png', name: '2019 고연전 단체티셔츠 - 할인', count:'1', option: '기본선택', price: '20000', },
			],
			shippingInfo: {
				name: '홍길동',
				address: '서울시 성동구 뚝섬로 1길 10 트러스톤빌딩 4층',
				message: '안전배송 부탁드려요',
				phone: '010-7890-1234'
			},
			orderInfo: {
				name: '홍길동',
				phone: '010-7890-1234',
				email: 'abc@abc.com',
				price: 66000,
				shipping: 5000,
				discount: 0,
				sum: 0,
				datetime: '2020-01-01',
				ordernum: '123456',
				status: '배송 준비',
			}
		}
	},
	methods: {
		matchBoard() {
			for(let i in this.boardMenu) {
				if ((this.$route.query.board || this.$route.params.board) == this.boardMenu[i].path) {
					return this.boardMenu[i].name;
				}

				if(this.$route.query.edit == this.boardMenu[i].path) {
					return this.boardMenu[i].name;
				}
			}
		},
		changeCommaPrice(price) {
			return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
		sum() {
			return this.orderInfo.price + this.orderInfo.shipping
		}
	}
}