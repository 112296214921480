/*
@Author: graceKim
@Date: 2021.01.24
*/

import axios from 'axios'

const host = "https://service.hwatong.or.kr/"
// const host = "http://localhost:3000"

const ajax = axios.create({
	baseURL: host,
	headers : {
        "Authorization" : getCookie('session'),
    },
	timeout: 30000
});

function getCookie(name) {
    var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value? value[2] : null;
}

export default {
    async login(no, name) {
        return await ajax.post('/user/signin', {
            login_id : `${no}_${name}`,
            no : no,
            name : name
        });
    },

    async getMy() {
        return await ajax.get('/user/info', {
            params : {
                token : decodeURI(getCookie("TOKEN_KEY"))
            }
        })
    },

    //// Payment
    async getPaymentInicisCard(form) {
        return await ajax.post(`/payment/card`, form);
    },
    async getOrderInfo(orderId) {
        return await ajax.get(`/payment/check/order`, {
            params : {
                orderId : orderId
            }
        });
    },
    //// ./Payment

    //// Product
    async getProductList() {
        return await ajax.get(`/product/list`);
    },

    async getProductDetail(id) {
        return await ajax.get(`/product/detail`, {
            params : {
                id : id
            }
        });
    },

    //// ./Product

	async getList(category, period, mode, keyword, offset, length) {
        return await ajax.get(`/board/${category}/list`, {
        	params : {
        		period,
        		mode,
        		keyword,
        		offset,
        		length
        	}
        });
    },
    async getDetail(id) {
        return await ajax.get(`/board/${id}`)
    },
    async noticeSet(array) {
        return await ajax.post('/board/notice/set', {
            id : array
        });
    },
    async noticeUnset(array) {
        return await ajax.post('/board/notice/unset', {
            id : array
        });
    },
    async removeMulti(array) {
        return await ajax.post('/board/remove/multi', {
            id : array
        }, {
            headers: {
                "Authorization": getCookie("TOKEN_KEY")
            }
        });
    },
    async write(category, form) {
        return await ajax.post(`/board/${category}/write`, form, {
            headers:{
                "Content-Type": "multipart/form-data",
                "Authorization": getCookie("TOKEN_KEY")
            }
        })
    },
    async update(id, form) {
        return await ajax.post(`/board/${id}/update`, form, {
            headers:{
                "Content-Type": "multipart/form-data",
                "Authorization": getCookie("TOKEN_KEY")
            }
        })
    },
}