<template>
	<header id="Header">
		<div class="wrapper">
			<div class="logo"><router-link to="/"><img src="@/assets/image/header-logo.svg"></router-link></div>
			<ul class="menubar">
				<li class="menu"><router-link to="/intro">교우회장 인사말</router-link></li>
				<li class="menu"><router-link to="/organization">교우회 조직도</router-link></li>
				<li class="menu"><a :class="$route.path.indexOf('board') != -1 ? 'router-link-exact-active' : ''" v-b-toggle.collapse>교우회 게시판</a></li>
				<li class="menu"><router-link to="/partners">교우기업</router-link></li>
				<li class="menu"><a :class="$route.path.indexOf('shop') != -1 ? 'router-link-exact-active' : ''" v-b-toggle.collapse2>화통회샵</a></li>
				<li class="menu" v-if="!isSignin"><a v-b-modal.login>로그인</a></li>
				<li class="menu" v-if="isSignin"><router-link to="/mypage" :class="$route.path.indexOf('mypage') != -1 ? 'router-link-exact-active' : ''">마이페이지</router-link></li>
			</ul>
		</div>
		<b-collapse id="collapse" ref="collapse" accordion="my-accordion" role="tabpanel">
			<ul class="depth2">
				<li v-for="list in boardMenu" :key="list.name">
					<router-link :to="'/board/'+list.path">{{list.name}}</router-link>
				</li>
			</ul>
		</b-collapse>

		<b-collapse id="collapse2" ref="collapse2" accordion="my-accordion" role="tabpanel">
			<div class="depth2">
				<li v-for="list in shopMenu" :key="list.name">
					<router-link :to="list.path" @click="history.go()">{{list.name}}</router-link>
				</li>
			</div>
		</b-collapse>

		<b-modal id="login" ref="login" hide-header hide-footer centered>
			<div class="modal-body boardModal">
				<h5>화통회 로그인</h5>
				<form class="form" @submit="login">
					<div class="form-group">
						<label>학번</label>
						<input v-model="loginData.no" class="form-control" type="text" name="" placeholder="학번을 입력해주세요 (ex : 99)">
					</div>
					<div class="form-group">
						<label>이름</label>
						<input v-model="loginData.name" class="form-control" type="text" name="" placeholder="이름을 입력해주세요 (ex : 김건우)">
					</div>
					<div class="form-group">
						<button class="loginBtn">로그인</button>
					</div>
				</form>
			</div>
		</b-modal>
	</header>
</template>

<script>
import store from '@/assets/js/store.js'	
import api from '@/api/board.js';
import cookies from 'vue-cookies';

export default {
	created() {
	},
	mixins: [store],
	data() {
		return {
			depth2: false,
			loginData : {
				name : '',
				no : ''
			}
		}
	},
	watch: {
		$route() {
			if(this.$route.path !== '/board') {
				this.$refs.collapse.show = false;
			}

			if(this.$route.path !== '/shop') {
				this.$refs.collapse2.show = false;
			}
		}
	},
	computed: {
		isSignin() {
			if(cookies.isKey("TOKEN_KEY")) {
				return true
			} else {
				return false
			}
		},
	},
	methods: {
		async login(e) {
			e.preventDefault();

			const result = await api.login(this.loginData.no, this.loginData.name);

			if(result.data.code === 0) {
				cookies.set('TOKEN_KEY', result.data.token);
				this.$refs['login'].hide()
				this.$router.go();
			} else {
				alert("교우회 등록 회원이 아닙니다. 학번과 이름을 다시 확인해주세요.")
			}
		},
		async logout() {
			cookies.remove('TOKEN_KEY')
			this.$router.go();
		}
	}
}
</script>

<style lang="scss">
#Header { width:100%; height: 80px; border:1px solid #eee;
	.wrapper { display: flex; height: inherit; align-items: center;
		.logo { width:220px; }
		.menubar {margin-left:auto; display: flex; height:auto; align-items:center;
			.menu {margin-left:38px; cursor:pointer;
				a {display: block; font-size:1.125rem; color:rgba(0,0,0,0.4); font-weight:600; 
					&.router-link-exact-active {color:#000; font-weight:bold;}
					// &.not-collapsed {color:#000; font-weight:bold;}
					&.not-collapsed {color:#000; font-weight:bold;}
				}
			}
		}
	}
	.depth2 {width:100%; height:40px; display:flex; align-items:center; justify-content:center; background:#fafcfc; border-bottom:1px solid #eee; position: relative; z-index: 1;
		li {color:rgba(0,0,0,0.6); font-size:0.875rem; margin:0 15px; height: inherit; 
			a { display: block; padding:8px 0; 
				&.router-link-exact-active {border-bottom:3px solid #8b0028; color:#8b0028; font-weight: bold;}
			}
		}
	}
}

button.loginBtn {width:100%; height:60px; border-radius: 5px; background:#8b0028; color:#fff; font-size:1.125rem; margin-top:30px;}

.boardModal {
	h5 {
		font-size: 2rem !important;
		font-weight: 700;
		margin-bottom: 30px;
		width: 100%;
		text-align: center;
	}
	.form {
		.form-group {
			margin-bottom: 10px;
			label {
				font-size: 1rem;
				font-weight: 500;
				margin-bottom: 10px;
			}
		}
	}
}
</style>