<template>
	<div id="Cart" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>장바구니</h2>
			</div>
			<div class="content">
				<table>
					<thead>
						<tr>
							<th>
								<!-- <b-form-checkbox v-model="allSelected"></b-form-checkbox> -->
							</th>
							<th>상품 정보</th>
							<th>수량</th>
							<th>주문금액</th>
							<!-- <th>배송비</th> -->
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in cartList" :key="item.id">
							<td>
								<!-- <b-form-checkbox v-model="selectedCart" :value="list._id"></b-form-checkbox> -->
							</td>
							<td>
								<router-link to="/" class="d-flex">
									<div class="imgbox"><img :src="productData[item.id].thumbnail"></div>
									<div class="txtbox">
										<h6>{{productData[item.id].name}}</h6>
										
									</div>
								</router-link>
							</td>
							<td>
								<div class="numberBtn">
									<div class="value-button" id="decrease" @click="decreaseValue(item.id)" value="Decrease Value">-</div>
									<input type="number" id="number" v-model="item.count" readonly/>
									<div class="value-button" id="increase" @click="increaseValue(item.id)" value="Increase Value">+</div>
								</div>
							</td>
							<td>{{changeCommaPrice(productData[item.id].price * item.count)}}원</td>
							<!-- <td>
								<p class="shipping">2,500원</p>
							</td> -->
							<td>
								<!-- <b-button size="sm" variant="outline-dark">삭제</b-button> -->
							</td>
						</tr>
					</tbody>
				</table>
				<div class="orderAmountBox row">
					<div class="col-4">
						<h5>주문금액 합계</h5>
						<p class="amount">{{changeCommaPrice(this.orderPrice - this.deliveryPrice)}}원</p>
						<span>+</span>
					</div>
					<div class="col-4">
						<h5>배송비 합계</h5>
						<p class="amount">{{changeCommaPrice(this.deliveryPrice)}}원</p>
						<span>=</span>
					</div>
					<div class="col-4">
						<h5>총 주문금액</h5>
						<p class="amount all">{{changeCommaPrice(this.orderPrice)}}원</p>
					</div>
				</div>
				<div class="bottom">
					<button @click="$router.history.push(`/payment?count=${count}`)">바로 구매</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from '@/assets/js/store.js'
import api from '@/api/board.js'
import cart from '@/assets/js/cart.js'

export default {
	created() {
		this.initList();
	},
	mixins: [store, cart],
	data() {
		return {
			allSelected: false,
			selectedCart: [],
			count : 1,
			price : 45000,
			deliveryPrice : 2500,
			productData : {
				'1' : {
					id : '',
					name : '',
					price : 0,
					thumbnail : '',
					description : ''
				},
				'2' : {
					id : '',
					name : '',
					price : 0,
					thumbnail : '',
					description : ''
				}
			}
		}
	},
	computed : {
		orderPrice() {
			let price = 0;

			this.cartList.forEach(item => {
				price += this.productData[item.id].price * item.count;
			})

			return price + this.deliveryPrice;
		}
	},	
	watch: {
		allSelected(newValue) {
			if(newValue == true) {
				for(let i in this.shoppingList) {
					this.selectedCart.push(this.shoppingList[i]._id);
				}
			} else if(newValue == false) {
				this.selectedCart = [];
			}
		},
		selectedCart(newValue) {
			if(newValue.length == 0) {
				this.allSelected = false;
			} else if(newValue.length == this.shoppingList.length) {
				this.allSelected = true;
			}
		}
	},
	methods: {
		increaseValue(id) {
			// var value = parseInt(document.getElementById('number').value, 10);
			// value = isNaN(value) ? 0 : value;
			// value++;
			// document.getElementById('number').value = value;
			this.increaseCartCount(id);
		},
		decreaseValue(id) {
			// var value = parseInt(document.getElementById('number').value, 10);
			// value = isNaN(value) ? 0 : value;
			// value < 1 ? value = 1 : '';
			// value--;
			// document.getElementById('number').value = value;
			this.decreaseCartCount(id);
		},
		async initList(){
			this.cartList.forEach(async (item) => {
				let {data} = await api.getProductDetail(item.id);
				data = data.data;
				
				this.productData[item.id] = data;
			})
		}
	}
}
</script>

<style lang="scss">
#Cart {
	.content {width:100%; margin-top:40px;
		table {width:100%;
			th {background:#fafafc; height:40px; font-size:0.875rem; color:rgba(0,0,0,0.5); text-align:center;
				&:nth-child(2) {text-align: left; padding:0 10px;}
				input[type="checkbox"] {
					+ label {
						&:before {top:-12px;}
					}
					&:checked + label:after {top:-12px;}
				}
			}
			td {padding:24px 0; vertical-align:middle; text-align:center; border-bottom:1px solid #f4f4f4;
				&:nth-child(1) {width:60px;}
				&:nth-child(2) {width:60%;}
				&:nth-child(3) {width:10%;}
				&:last-child {width:10%;}
				.imgbox {width:120px; border:1px solid #eee;
					img {width:100%; object-fit:cover;}
				}
				.d-flex {height:auto; align-items: center;
					.txtbox {text-align: left; padding-left:24px;
						h6 {font-size:1rem; color:#000; font-weight:bold;}
						span {padding-top:7px; font-size:0.75rem; color:#000; font-weight:300;}
					}
				}
				button {padding:5px 15px;}
				input[type="checkbox"] {
					+ label {
						&:before {top:-14px;}
					}
					&:checked + label:after {top:-14px;}
				}
			}
			input[type="checkbox"] { position:absolute; width:1px; height:1px; padding:0; margin:-1px; overflow:hidden; clip:rect(0,0,0,0); border:0; 
				+ label {display:inline-block; position:relative; padding-left:30px; cursor:pointer; font-size:0.875rem;
					&:before {content:''; position:absolute; left:0; width:14px; height:14px; text-align:center; background:#fff; border:0; box-sizing:border-box; background-image:url("../../assets/image/small-check-unchecked@3x.png"); background-repeat:no-repeat; background-size:14px;}
				}
				&:checked + label:after{ content: ''; position:absolute; left:0; width:14px; height:14px; background-image:url("../../assets/image/small-yellow-checked@3x.png"); background-repeat:no-repeat; background-size:14px;}
			}
		}
		.orderAmountBox { width:100%; border:1px solid #000; border-radius:4px; margin-top:40px; overflow: hidden; text-align:center;
			.col-4 {padding:0; position: relative;
				span {position:absolute; top:50%; right:0; transform:translate(-50%, 20%);}
				h5 {width:100%; height:40px; background:#fafafc; color:rgba(0,0,0,0.5); font-size:0.875rem; font-weight:bold; display:flex; align-items: center; justify-content: center;}
				p {height: 80px; display:flex; font-size: 1.125rem; color:#000; font-weight:normal; align-items: center; justify-content: center;
					&.all {font-size:1.5rem; color:#ff5746; font-weight: bold;}
				}
				span {display: block; font-size:1.25rem; font-weight:bold;}
			}
		}
		.bottom {width:100%; margin-top:50px; text-align:center;
			button {width:300px; background:#8b0028; height:60px; font-size:1.25rem; color:#fff; font-weight:bold; border-radius:10px;}
		}
	}

	.numberBtn {
		margin: 0 auto;
		text-align: center;
		display: flex;
		height: auto;
		align-items: center;
		justify-content: center;
	}

	.value-button {
		border: 1px solid #ddd;
		margin: 0px;
		width: 26px;
		height: 26px;
		text-align: center;
		vertical-align: middle;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.value-button:hover {
		cursor: pointer;
	}

	.numberBtn #decrease {
		margin-right: -4px;
		border-radius: 3px 0 0 3px;
	}

	.numberBtn #increase {
		margin-left: -4px;
		border-radius: 0 3px 3px 0;
	}

	.numberBtn #input-wrap {
		margin: 0px;
		padding: 0px;
	}

	input#number {
		text-align: center;
		border: none;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		border-left:1px solid #ddd;
		margin: 0px;
		width: 45px;
		height: 26px;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
</style>