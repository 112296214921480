<template>
	<div id="Intro" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>교우회장 인사말</h2>
			</div>
			<div class="content">
				<div class="imgbox"><img src="@/assets/image/image-profile@3x.png"></div>
				<b>안녕하십니까?</b>
				<br><br>
				<p>
				사랑하는 고려대학교 화공생명공학과 선후배 교우 여러분!<br/>
				2022년 2월 정기총회에서 제16대 교우회장으로 선출된 최영호(82) 인사 올립니다.<br/>
				<br/>
				저는 대학 시절에 학회장을 하면서 학회지를 만들기 위해 <br/>
				재학생들과 함께 졸업하신 선배님들을 찾아다니며 인사드리면서 화공생명공학과 교우회와 첫 인연을 맺었으며, <br/>
				졸업 후에도 훌륭하신 선배님들과 교우회 활동을 꾸준히 하였습니다.<br/>
				<br/>
				제가 입학 40년이 되는 뜻깊은 해에 교우회장으로 인사드리게 되어 무한한 영광으로 생각합니다. <br/>
				그동안 선배님들에게 받았던 사랑을 후배들에게 전달하는 소중한 기회라 여기고 <br/>
				무거운 책임감과 함께 교우회 발전을 위하여 힘닿는 대로 열심히 하겠습니다.<br/>
				<br/>
				제가 취임하면서 내세운 모토는 “교우간 네트워크 강화와 지방 교우회 활성화를 통한 명실상부한 화공생명공학과 교우회 정립”입니다.<br/>
				전국에 흩어져 있는 교우님들이 다시 활발하게 참여할 수 있는 교우회를 만들기 위해 <br/>
				직접 지방도 방문하여 막걸리 한잔하면서 학과 발전과 교우회 발전을 위한 고견을 청취하겠습니다. <br/>
				지금은 코로나 때문에 힘든 상황이지만 여건이 호전되면 꼭 실행하도록 하겠습니다.<br/>
				<br/>
				더욱이 내년 2023년은 우리 화공생명공학과가 창립된 지 60주년이 되는 뜻깊은 해입니다. <br/>
				2013년에 50주년 행사를 성대하게 치렀던 것을 기억합니다.<br/>
				내년 60주년 행사도 내실 있고 기억에 남는 행사가 될 수 있도록 <br/>
				교우회 임원진들 그리고, 학과와 상의하여 차근차근 준비해 나가도록 하겠습니다.<br/>
				<br/>
				그간 교우회를 위해서 애쓰신 선배 교우회장님, 사무국에서 함께 봉사한 후배 교우, 물심양면으로 협조해주신 학과장님, <br/>
				그리고 존경하는 교수님들, 학과 행정실, 학생회와 재학생들의 유기적인 협력이 있었기에 <br/>
				화공생명공학과 교우회는 다른 어느 학과보다 체계적이고 내실 있는 교우회로 자리매김하였다고 자부합니다. <br/>
				앞으로도 화공생명공학과에 대한 선배님들의 애정과 희생이 헛되지 않도록 <br/>
				그 전통을 이어받아 더욱 발전하는 화공생명공학과 교우회가 될 수 있도록 노력하겠습니다.<br/>
				<br/>
				자랑스럽고 존경하는 고려대학교 화공생명공학과 교우 여러분!<br/>
				우리 교우회가 지속적으로 발전한 것은 앞서 말씀드린 바와 같이 선후배님의 참여와 봉사가 그 밑거름이 되었다고 확신하며, <br/>
				이 자리를 빌려 깊은 감사의 말씀을 드립니다.<br/>
				<br/>
				임기 동안 내실 있는 교우회, 즐거운 교우회, 상부상조하는 교우회가 되는 것이 저의 소망입니다. <br/>
				또한, 교우회 홈페이지를 통해 더 많이 소통할 것을 약속드리며, <br/>
				자랑스러운 고려대학교 화공생명공학과 교우로써 각자 맡은 자리에서 최선을 다하고 계실 교우님들을 힘차게 응원합니다.<br/>
				<br/>
				끝으로 교우님들 모두 “코로나 19”를 슬기롭게 극복하시기 바라며 희망차고 건강한 2022년 한 해가 되길 진심으로 기원하면서 <br/>
				앞으로 선후배님들의 아낌없는 격려와 협조를 부탁드립니다.<br/>
				<br/>
				감사합니다.<br/>
				<br/>
				<br/>
				16대 교우회장 82학번 최 영 호<br/>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>

<style lang="scss">
#Intro { 
	.content {
		.imgbox {width:247px; margin-bottom:22px;}
		p {
			color: #333;
		}
	}
}
</style>