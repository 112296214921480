<template>
	<div id="Shop" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>화통회샵</h2>
			</div>
			<div class="row">
				<div class="col-4" v-for="(item,index) in list" :key="index">
					<router-link :to="'/shop/detail/'+item.id" class="boxwrap">
						<div class="imgbox"><img :src="item.thumbnail"></div>
						<div class="txtbox">
							<p v-html="item.name"></p>
							<h6>{{changeCommaPrice(item.price)}}원</h6>
						</div>
					</router-link>
				</div>
			</div>
			<!-- <button class="cartBtn"><img src="@/assets/image/cart@3x.png"></button> -->
		</div>
	</div>
</template>

<script>
import store from '@/assets/js/store.js'	
import api from '@/api/board.js'	

export default {
	created() {
		this.loadProductList();
	},
	mixins: [store],
	data() {
		return {
			list : [{
				id : '',
				name : '',
				price : '',
				thumbnail : ''
			}]
		}
	},
	methods: {
		async loadProductList() {
			const result = await api.getProductList();

			this.list = result.data.list;
		}
	}
}
</script>

<style lang="scss">
#Shop {
	.wrapper { position:relative; text-align:right;
		.titlebox {text-align: left;}
		.boxwrap {width:100%; display:block; padding-bottom:60px; text-align: left;
			.imgbox {width:100%; height:340px; overflow:hidden; border:1px solid #eee;
				img {height:inherit; object-fit:cover;}
			}
			.txtbox {padding-top:25px;
				p {font-size:1.125rem; color:rgba(0,0,0,0.6); padding-bottom:10px;}
				h6 {font-size:1.25rem; color:#000; font-weight:bold;}
			}
		}
		.cartBtn {width:100px; height:100px; position:sticky; bottom:60px; 
			img {width:100%; box-shadow:0 2px 4px 0 rgba(0,0,0,0.5); border-radius:50px;;}
		}
	}
}
</style>