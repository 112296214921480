<template>
	<div id="Board" class="container">
		<div class="wrapper">
			<h2>{{boardName}}</h2>
			<div class="searchBox">
				<b-form-select v-model="substance" class="category">
					<option v-for="list in substanceOptions" :key="list.value" :value="list.value">{{list.text}}</option>
				</b-form-select>
				<div class="inputBox">
					<input type="text" v-model="search" placeholder="검색어를 입력하세요"/>
					<button>검색</button>
				</div>
				<b-form-select v-model="perPage" class="perPage"> 
					<option v-for="list in perPageOptions" :key="list.value" :value="list.value">{{list.text}}</option>
				</b-form-select>
				<button class="writeBtn" @click="goWrite">글쓰기</button>
			</div>
			<div class="list" v-if="this.$route.params.board !== 'gallery'">
				<b-table
					:tbody-tr-class="rowClass"
					:class="[{adminTable : admin == true}, {}]"
					ref="selectableTable"
					show-empty
					small
					stacked="md"
					:items="boardList"
					:fields="boardHeader"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:filter-included-fields="filterOn"
					@filtered="onFiltered"
					:sort-by.sync="boardList.length > 0 ? sortBy : ''"
					:sort-desc.sync="boardList.length > 0 ? sortDesc: false"
					:sort-direction="sortDirection"
					:selectable="admin ? true : false"
					:select-mode="selectMode"
					@row-selected="onRowSelected"
					empty-text="게시물이 없습니다"
				>	
					<template v-slot:cell(selected)="{ rowSelected }">
				        <template v-if="rowSelected && admin">
				          <span aria-hidden="true">&check;</span>
				        </template>
				        <template v-else>
				          <span aria-hidden="true">&nbsp;</span>
				        </template>
				    </template>
					<template v-slot:cell(rownum)="row">
        				<span v-if="row && row.item.is_notice == 'Y'" class="notice">공지</span>
        				<span v-else>{{row.item.rownum}}</span>
      				</template>
					<template #cell(title)="data">
						<router-link :to="'/board/detail/'+ $route.params.board + '/' + data.item.id" v-html="data.value"></router-link>
					</template>
					<template #cell(create_datetime)="data">
						<p>{{formatDate(data.value)}}</p>
					</template>
				</b-table>

				<div class="bottom d-flex" v-if="admin == true">
		        	<button v-if="allSelected == false" class="selectBtn" @click="selectAllRows">
		        		<div><span>&nbsp;</span></div>
		        		전체 선택
		        	</button>
		        	<button v-if="allSelected == true" class="selectBtn" @click="clearSelected">
		        		<div><span>&check;</span></div>
		        		전체 선택 해제
		        	</button>

		        	<div class="right ml-auto">
		        		<router-link style="margin-right: 10px;" :to="`/board/${selected.length > 0 ? selected[0].id : ''}`"><button :disabled="selected.length === 0">수정</button></router-link>
		        		<button v-b-modal.removeBoard>삭제</button>
		        	</div>
		        </div>

				<b-pagination
		          	v-model="currentPage"
		          	:total-rows="count"
		          	:per-page="perPage"
		        >
		        	<template #prev-text><img src="@/assets/image/pagination-arrow-left@3x.png"></template>
		        	<template #next-text><img src="@/assets/image/pagination-arrow-right@3x.png"></template>
		        </b-pagination>
			</div>
			<div class="gallery" v-else>
				<div class="row">
					<div class="col-3" v-for="(item, index) in boardList" :key="'board' + index">
						<router-link :to="'/board/detail/'+ $route.params.board + '/' + item.id">
							<div class="imgbox" :class="{img: item.thumb}"><img :src="item.thumb ? item.thumb : require('@/assets/image/favicon-logo@3x.png')"></div>
							<div class="txtbox">
								<p v-html="item.title"></p>
								<span>{{formatDate(item.create_datetime)}} | {{item.hit}}</span>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<b-modal id="removeBoard" hide-header hide-footer size="default" centered>
			<h3>게시글 삭제</h3>
			<div class="txtbox">
				<p>선택하신 게시물을 삭제하시겠습니까?</p>
				<div class="btnWrap">
					<button class="releaseNoticeBtn" @click="remove">게시글 삭제</button>
					<button @click="$bvModal.hide('removeBoard')">닫기</button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import store from '@/assets/js/store.js'	
import board from '@/api/board'
import cookies from 'vue-cookies';

export default {
	mixins: [store],
	created() {
		this.admin = this.$root.isLogin;

		if(this.$route.params.board) {
			this.category = this.$route.params.board
		}
		if(this.$route.query.board) {
			this.category = this.$route.query.board
		}
	},
	mounted() {
		this.loadBoardList()
	},
	watch: {
		'$route.params.board'() {
			this.category = this.$route.params.board
			this.loadBoardList()
		}
	},
	data() {
		return {
			admin: true,
			currentPage: 1,
			filter: null,
			filterOn: [],
			sortBy: 'is_notice',
			sortDesc: true,
			sortDirection: 'desc',
        	selected: [],
        	allSelected: false,
        	selectMode: 'multi',
			search: '',
			substance: 'all',
			substanceOptions: [
				{ text: '제목+내용', value: 'all' },
				{ text: '제목', value: 'title' },
				{ text: '내용', value: 'content' },
			],
			perPage: 20,
			perPageOptions: [
				{ text: '20개씩 보기', value: 20 }, 
				{ text: '50개씩 보기', value: 50 }, 
				{ text: '100개씩 보기', value: 100 }, 
			],
			boardHeader: [
				{ key: 'selected', label: ''},
				{ key: 'rownum', label: '번호' },
				{ key: 'title', label: '제목' },
				{ key: 'create_datetime', label: '작성일' },
				{ key: 'hit', label: '조회' },
			],
			count: 1,
			category: null,
			boardList: [
				{
					is_notice: 'N'
				}
			],
		}
	},
	computed: {
		boardName() {
			return this.matchBoard()
		}
	},
	methods: {
		rowClass(item) {
	        if (item && item.is_notice == 'Y') return 'table-secondary'
      	},
		 onFiltered(filteredItems) {
	        this.count = filteredItems.length
	        this.currentPage = 1
	    },
	    onRowSelected(items) {
        	this.selected = items
      	},
	    noticeClass(item) {
	    	if(item.notice) return 'notice'
	    },
		selectAllRows() {
        	this.$refs.selectableTable.selectAllRows()
        	this.allSelected = true;
      	},
      	clearSelected() {
        	this.$refs.selectableTable.clearSelected()
        	this.allSelected = false;
      	},
		async loadBoardList() {
			let response = await board.getList(this.category, 'all', this.substance, this.search, 0, 10000)
			this.count = response.data.data.count;
			this.boardList = response.data.data.list;

			if(response.data.data.list.length == 0) {
				this.boardList = []
			}
		},
		async remove() {
			const id = this.selected.map(item => {
      			return item.id;
      		})
			await board.removeMulti(id);
			this.loadList();
			this.$bvModal.hide('removeBoard');
		},
		formatDate(date) {
			if (date) {
				var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear()
				if (month.length < 2) {
					month = '0' + month
				}
				if (day.length < 2) {
					day = '0' + day
				}
				return [year, month, day].join('-')
			} else {
				return null
			}
		},
		goWrite() {
			if(cookies.isKey("TOKEN_KEY")) {
				this.$router.replace('/board/'+this.$route.params.board+'/edit')
			} else {
				this.$emit('signin')
			}
		}
	}
}
</script>

<style lang="scss">
#Board { 
	.wrapper {
		h2 {}
		.searchBox {width:100%; display: flex; height:auto; align-items:center;
			select { 
				&.category {margin-right:10px;}
				&.perPage {margin-left:10px;}
			}
			.inputBox { border-radius:3px; height:32px; box-sizing: border-box;
				input {border-radius:0; border-right:0; font-size:0.875rem; height:inherit; border-radius:3px 0 0 3px; padding:5px;}
				button {font-size:0.875rem; color:#fff; background:#8b0028; padding:5px 12px; border:1px solid #8b0028; height:32px; border-radius:0 3px 3px 0;}
			}
			button.writeBtn {width:70px; margin-left:auto; font-size:0.875rem; color:#fff; font-weight:500; border-radius:4px; height:32px; background:#8b0028;}
		}
		.list {width:100%; margin-top:15px;
			table {width:100%; font-size:0.875rem; 
				thead {
					tr {border-top:1px solid #8b0028; border-bottom:1px solid #8b0028;
						th {border-bottom:inherit; padding:12px 5px; text-align: center;}
					}
				}	
				tbody {
					tr { border-bottom:1px solid #c7c7c7; 
						&.notice {font-weight: bold;}
						td {padding:15px 5px; text-align:center;
							// &:nth-child(1) {min-width:60px;}
							// &:nth-child(2) {}
							// &:nth-child(3) {min-width:120px;}
							// &:nth-child(4) {min-width: 60px;}
						}
					}
				}
			}
		}
		.gallery {width:100%; margin-top:15px; border-top:1px solid #8b0028; padding-top:25px;
			a {width:100%; display:block; margin-bottom:50px;
				.imgbox {margin-top:20px; width:100%; height:160px; overflow:hidden; border:1px solid #ddd;
					img {
						height:inherit; 
						object-fit:contain;
					}
					&.img {
						img {
							object-fit: cover;
						}
					}
				}
				.txtbox {padding-top:14px;
					p {font-weight:bold; font-size:0.875rem; line-height:1.4; padding-bottom:10px;}
					span {display:block; font-size:0.875rem; color:rgba(0,0,0,0.6);}
				}
			}
		}
		.pagination { align-items: center; justify-content: center; margin-top:40px;
			li {
				&:first-child {display:none;}
				&:last-child {display:none;}
				button {padding:0; display:block; width:40px; height:40px;}
				span {padding:0; display:block; width:40px; height:40px;
					img {width:40px; height:40px;}
				}
				&.disabled {cursor:no-drop;}
			}
			.page-link { width:40px; height: 40px; border:0; display: inline-block; font-size:0.75rem; 

			}
			.page-item {
				button {background-color:#fff; border:1px solid #d7d7d7; color:#555;}
				&.active {
					button {background-color:#3b3f4d; border:1px solid #3b3f4d; color:#fff;}
				}
			}
		}
	}
}

#noticeRelease {
	.modal-dialog {width:28%;}
	.modal-body {padding:30px;}
	h3 {font-size:1.125rem; padding-bottom:9px; border-bottom:1px solid #000; font-weight:bold;}
	.txtbox {width:100%; padding:15px 0 0;
		p {font-size:0.875rem; padding-bottom:30px;}
		.btnWrap {width:100%; text-align:right;
			button {padding:8px 15px; color:#fff; font-size:0.875rem; background:#000; border-radius:5px; margin-left:10px;
				&.changeNoticeBtn {background:#0b6cff;}
				&.releaseNoticeBtn {background:#ff5746;}
			}
		}
	}
}

#noticeSetting {
	.modal-dialog {width:28%;}
	.modal-body {padding:30px;}
	h3 {font-size:1.125rem; padding-bottom:9px; border-bottom:1px solid #000; font-weight:bold;}
	.txtbox {width:100%; padding:15px 0 0;
		p {font-size:0.875rem; padding-bottom:30px;}
		.btnWrap {width:100%; text-align:right;
			button {padding:8px 15px; color:#fff; font-size:0.875rem; background:#000; border-radius:5px; margin-left:10px;
				&.changeNoticeBtn {background:#0b6cff;}
				&.releaseNoticeBtn {background:#ff5746;}
			}
		}
	}
}


#removeBoard {
	.modal-dialog {width:28%;}
	.modal-body {padding:30px;}
	h3 {font-size:1.125rem; padding-bottom:9px; border-bottom:1px solid #000; font-weight:bold;}
	.txtbox {width:100%; padding:15px 0 0;
		p {font-size:0.875rem; padding-bottom:30px;}
		.btnWrap {width:100%; text-align:right;
			button {padding:8px 15px; color:#fff; font-size:0.875rem; background:#000; border-radius:5px; margin-left:10px;
				&.changeNoticeBtn {background:#0b6cff;}
				&.releaseNoticeBtn {background:#ff5746;}
			}
		}
	}
}	
</style>