export default {
	created() {
		this.loadCartList();
	},
	data() {
		return {
			cartList : []
		}
	},
	methods: {
		loadCartList() {
			if(localStorage.getItem("cart")) {
				try {
					this.cartList = JSON.parse(localStorage.getItem("cart"))
				} catch(e) {
					this.cartList = []
				}
			}
		},
		saveCartList() {
			localStorage.setItem("cart", JSON.stringify(this.cartList));
		},
		clearCartList() {
			this.cartList = [];
			this.saveCartList();
		},
		addCart(id) {
			let already = false;
			for(let i = 0, len = this.cartList.length; i < len; i++) {
				if(this.cartList[i].id === id) 
					already = true;
			}

			if(!already) {
				this.cartList.push({
					id : id,
					count : 1
				})
			}

			this.saveCartList();
		},
		removeCart(id) {
			let already = -1;
			for(let i = 0, len = this.cartList.length; i < len; i++) {
				if(this.cartList[i].id === id) 
					already = i
			}

			if(already >= 0) {
				this.cartList.remove(already);
			}

			this.saveCartList();
		},
		increaseCartCount(id) {
			let already = -1;
			for(let i = 0, len = this.cartList.length; i < len; i++) {
				if(this.cartList[i].id === id) 
					already = i
			}

			if(already >= 0) {
				this.cartList[already].count += 1;
			}

			this.saveCartList();
		},
		decreaseCartCount(id) {
			let already = -1;
			for(let i = 0, len = this.cartList.length; i < len; i++) {
				if(this.cartList[i].id === id) 
					already = i
			}

			if(already >= 0) {
				if(this.cartList[already].count > 1)
					this.cartList[already].count -= 1;
			}

			this.saveCartList();
		}
	}
}