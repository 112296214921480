<template>
	<div id="Organization" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>교우회 조직도</h2>
			</div>
			<div class="content">
				<div class="imgbox"><img src="@/assets/image/tree@3x.png"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>

<style lang="scss">
#Organization { 
	.titlebox {margin-bottom: 0;}
	.imgbox {width:100%; margin-top:0;}
}	
</style>