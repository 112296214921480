<template>
	<footer id="Footer">
		<div class="wrapper">
			<div class="row">
				<div class="col-3">
					<h6>고객센터</h6>
					<p>
						전화: 02 498 3060<br>
						팩스: 02 6280 9045<br>
						이메일: smartjack@smartjackwp.com
					</p>
				</div>
				<div class="col-9">
					<router-link to="/">개인정보 이용 약관</router-link>
					<router-link to="/">서비스 이용 약관</router-link>
					<p>
						화통회
						<span>|</span>
						대표자: 이길선
						<span>|</span>
						사업자등록번호: 107-82-78222
						<span>|</span>
						통신판매업신고번호: 제2021-경기광주-1545호<br> 
						주소: 경기도 광주시 밭말길 73, 403호(목현동)
					</p>
					<p>COPYRIGHT © 2019 Smartjack Co., Ltd. All rights reserved. </p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>

<style lang="scss">
#Footer {width:100%; background:rgba(0, 0, 0, 0.87); padding:65px 0 90px; margin-top:150px;
	.row { color:#fff;
		h6 {font-size:1.25rem; font-weight:600;}
		p {line-height:2; font-size:0.875rem;
			span {display:inline-block; padding:0 20px;}
		}
		a {display:inline-block; text-decoration:underline; margin-right:20px; font-size:0.875rem;}
	}
}
</style>