<template>
	<div id="Partners" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>교우기업</h2>
			</div>
			<div class="row">
				<div class="col-3" v-for="list in partnersList" :key="list.title" @click="goLink(list)" @mouseenter="mouseEnter($event, list)" @mouseleave="mouseLeave($event, list)">
					<div class="boxwrap">
						<div class="infobox">
							<span v-html="list.info"></span>
						</div>
						<div class="imgbox">
							<img :src="require('@/assets/image/partners/'+list.image)"></div>
						<div class="txtbox">
							<h6 v-html="list.name"/>
						</div>
					</div>
				</div>
			</div>
			<img ref="images" src="../assets/image/partners/aceandtech-img.jpg" style="display:none;">
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				partnersList: [
					{
						image: 'smartjack@3x.png',
						name: '스마트잭',
						link: 'https://lab-manager.com',
						info: '대표: 김건우(99)<br>주소: 서울시 성동구 성수일로 19, 5층 (유한타워)'
					},
					{
						image: 'unicham@3x.png',
						name: 'LG-유니참',
						link: 'https://www.sofybodyfit.co.kr/ko/',
						info: '대표: 이두환(68)<br>주소: 서울시 영등포구 의사당대로 97 (여의도동, 교보증권빌딩 3층)'
					},
					{
						image: 'nongshim@3x.png',
						name: '농심',
						link: 'http://www.nongshim.com/main/index',
						info: '대표: 신동원(76)'
					},
					{
						image: 'owenscorning@3x.png',
						name: '한국오웬스코닝',
						link: 'http://www.owenscorning.co.kr/new/main/main.asp',
						info: '대표: 송상호(80)<br>주소: 서울시 강남구 테헤란로 507 일송빌딩 18층'
					},
					{
						image: 'wooyeon@3x.png',
						name: '우연 특허법률사무소',
						link: 'http://m.wooyeonip.com/?act=main',
						info: '대표변리사: 반용병(81)<br>주소: 서울시 서초구 서초대로 53길 21, 찬형빌딩 5층'
					},
					{
						image: 'aceandtech@3x.png',
						name: '에이스엔텍㈜',
						info: '대표: 최영호(82)<br>주소: 경기도 안양시 동안구 벌말로 126 평촌 오비즈타워 602호'
					},
					{
						image: 'ones@3x.png',
						name: '㈜오네스',
						link: 'http://www.v45.com/',
						info: '대표: 이두형(83)<br>주소: 서울시 영등포구 은행로 37 (여의도동) 기계산업진흥회 본관 3층 - 화장품 및 화장용품 도매업'
					},
					{
						image: 'joomoonjin@3x.png',
						name: '㈜주문진규사',
						link: 'http://joomoonjin.com/default/',
						info: '대표: 홍만의(84)<br>주소: 서울시 중구 청파로 432, 2층'
					},
					{
						image: 'doosong@3x.png',
						name: '두송건설주식회사',
						link: 'http://www.doosong.com/',
						info: '대표: 송명근(86)<br>주소: 서울시 서초구 서초대로 396, 12층/ 콘크리트 및 철근 공사업'
					},
					{
						image: 'philand-onzi@3x.png',
						name: '필&온지 국제특허법률사무소',
						link: 'https://www.philonzi.com/',
						info: '변리사: 김상우(86)<br>주소: 서울시 서초구 서초중앙로 36, 3층 (서초동, 준영빌딩)'
					},
					{
						image: 'beecom@3x.png',
						name: '㈜비컴아이엔씨',
						link: 'http://www.beecominc.com/#/',
						info: '대표: 박종수(86)<br>주소: 서울시 강남구 밤고개로 1길 10 수서현대벤처빌 1928호, 상품종합도매업'
					},
					{
						image: 'kangin@3x.png',
						name: '강진관세사무소',
						link: 'http://www.chemcus.com/',
						info: '대표: 안태익(87)<br>주소: 인천 중구 공항동로 295번길 77-11, C동 404호'
					},
					{
						image: 'dongyang@3x.png',
						name: '동양제강',
						link: 'http://www.ropes.co.kr/',
						info: '연구소장: 차재혁(89)<br>주소: 부산 사하구 - 끈 및 로프 제조업'
					},
					{
						image: 'ipwiz@3x.png',
						name: '아이피즈국제특허법률사무소',
						link: 'http://ip-wiz.com/',
						info: '대표: 윤형근(92), 송인호(94)<br>주소: 서울시 강남구 역삼동 839-7 동림빌딩5층'
					},
					{
						image: 'wrfc@3x.png',
						name: '㈜우리에프씨',
						link: 'http://www.woorifc.co.kr/main/',
						info: '대표: 노현종(92)<br>주소: 경기도 하남시 조정대로 45 F-617호'
					},
					{
						image: 'fcmt@3x.png',
						name: '㈜FCMT',
						link: 'https://fcmt.co.kr/',
						info: '대표: 이정규(92)<br>주소: 안양 동안구'
					},
					{
						image: 'rafiq@3x.png',
						name: '㈜RAFIQ',
						link: 'https://rafiqcosmetics.com/',
						info: '대표: 이범주(97)<br>주소: 서울특별시 강남구 언주로323, 2층 202호'
					},
					{
						image: 'nine@3x.png',
						name: '나인국제특허법률사무소',
						link: 'http://www.ninepat.com/',
						info: '대표: 홍명기(01)<br>주소: 서울시 강서구 공항대로 247, 퀸즈파크나인 B동 1124호'
					},
					{
						image: 'a-1@3x.png',
						name: 'A1 Korea',
						link: 'http://a1kor.kr/index-01.html',
						info: '대표: 최성호(01)<br>주소:  서울특별시 동작구 현충로 164 (흑석동) 1층'
					},
					{
						image: 'ggun@3x.png',
						name: '(주)커뮤니케이션 꾼',
						link: 'https://ggun.modoo.at/',
						info: '대표: 양동주(87)'
					},
					{
						image: 'ivs@3x.png',
						name: '(주)IVS',
						link: 'http://www.validation.co.kr/',
						info: '대표: 안도영(78)<br>주소: 경기 용인시 기흥구 용구대로1890번길 42-8'

					},
					{
						image: 'ymfoodmall.gif',
						name: '영미산업(주)',
						link: 'https://www.ymfoodmall.com/',
						info: '대표: 이성표(81)<br>주소: 경기 용인시 처인구 중부대로1504번길 42 식품가공'

					},
					{
						image: 'enbioscience.png',
						name: '(주)이엔비에스',
						link: 'http://www.enbioscience.com/',
						info: '대표: 김홍본(83)<br>주소: 인천광역시 서구 도담4로 33 (오류동)'
					},
					{
						image: 'isan@3x.png',
						name: '이산컨설팅그룹',
						link: 'http://www.isan.co.kr/',
						info: '대표: 윤재성(90)<br>주소: 서울시 용산구 한강대로 40길 39-13, 301호'
					},
					{
						image: 'samjung.png',
						name: '삼정산업',
						link: 'http://bondmaster.co.kr/main.html',
						info: '대표: 조세익(68)<br>주소: 경기도 안성시 원곡면 승량길 177'
					},
						{
						image: 'incochem.png',
						name: '(주)인코켐',
						info: '대표: 김철수(71)<br>주소: 서울시 강남구 강남대로 310, 1612호 (역삼동, 유니온센타오피스텔)'
					},
						{
						image: 'cec@3x.gif',
						name: '기술사사무소 차스텍이앤씨(주)',
						link: 'http://www.charstech.com/index/index.php',
						info: '대표: 차순철(74)<br>주소: 서울시 강남구 남부순환로 355길 13, 301호'
					},
						{
						image: 'airsysone.png',
						name: '(주)에어시스원',
						info: '대표: 김형중(76)<br>주소: 서울시 관악구 관천로 99, 2F (신림동 진안빌딩)'
					},
						{
						image: 'myungshin.png',
						name: '(주)명신해운',
						info: '대표: 이홍주(81)<br>주소: 울산시 남구 돋질로 423(삼산동) 동심빌딩 3층'
					},
						{
						image: 'rbaplus.png',
						name: '(주)알비에이플러스',
						info: '대표: 이균원(82)<br>주소: 서울시 강남구 강남대로 310, 1007호 (역삼동, 유니온센타)'
					},
						{
						image: 'csglobal.png',
						name: '(주)CS 글로벌',
						link: 'http://www.cs-global.co.kr/',
						info: '대표: 한승동(83)<br>주소: 충남 서산시 대산읍 평신 1로 595-8'
					},
						{
						image: 'sdt.png',
						name: 'S.D.T',
						info: '대표: 남규원(83)<br>주소: 충남 부여군 장암면 의자로 579'
					},
						{
						image: 'dreamfac.png',
						name: '(주)드림팩토리',
						info: '대표: 이기섭(97)<br>주소: 경기도 하남시 미사강변서로 25, FB205호 (풍산동 미사테스타타워)'
					},
						{
						image: 'xiann@3x.png',
						name: '시안특허법률사무소',
						link: 'http://www.xiannip.com/#sec=top',
						info: '변리사: 성익재(98)<br>주소: 서울시 송파구 법원로 127, 1501호 (문정동, 문정대명벨리온)'
					},
						{
						image: 'hana chemical.png',
						name: '주식회사 하나이화',
						link: 'https://hanachemical.co.kr/',
						info: '대표: 정지웅(01)<br>주소: 경기도 화성시 장안면 삼수문길 25-19'
					},

				]
			}
		},
		methods: {
			goLink(list) {
			// 	if(list.link) {
			// 		window.open(list.link, "toolbar=no, menubar=no, scrollbars=no, resizable=yes");
			// 	} else {
			// 		window.open(this.$refs.images.src)
			// 	}
				

					if (list.name === '에이스엔텍㈜') {
						window.open(this.$refs.images.src)
					} else {
							if(list.link) {
								window.open(list.link, "toolbar=no, menubar=no, scrollbars=no, resizable=yes");
						  } else {
							 return;
							}
					}
					
			},
			

			mouseEnter: function(event, partner){
				this.toggleInfoPartnerVisible(event, 'visible', partner);
			},
			mouseLeave: function(event, partner){
				this.toggleInfoPartnerVisible(event, 'hidden', partner);
			},
			toggleInfoPartnerVisible: function (_event, visibility, partner) {
				if (partner.info === undefined) {
					return;
				}
				_event.target.childNodes.forEach(node1 => {
					node1.childNodes.forEach(node2 => {
						if (node2.classList.contains('infobox')) {
							node2.style.visibility = visibility;
						}
					})
				})
			}
		}
	}
</script>

<style lang="scss">
	#Partners {
		.row {
			.col-3 { margin-bottom:20px;
				.boxwrap {width:100%; border-radius:10px; box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.2); display:block; cursor: pointer; overflow: hidden; position: relative;
					.imgbox {width:100%; height:103px; overflow:hidden; text-align:center;
						img {width:80%; object-fit:contain; height:inherit;}
					}

					.txtbox {width:100%; height:48px; background:#fafcfc; padding:16px 20px; text-align: center;
						h6 {font-size:0.875rem; padding-bottom:10px; font-weight:bold;}
						p {font-size:0.875rem; overflow:hidden; text-overflow:ellipsis; word-wrap:break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; line-height:1.5em; height:3em;}
					}

					.infobox { position: absolute; visibility: hidden; padding: 20px; width: 100%; height: 100%; font-size:0.875rem; color: white; background-color: black;
								font-weight:bold; border-style: solid; border-color: white; border-radius:10px; line-height: 25px;
					}
				}
			}
		}
	}
</style>