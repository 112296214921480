<template>
	<div id="NonMemberCart" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>비회원 주문조회</h2>
			</div>
			<div class="boxwrap">
				<h6>주문 조회</h6>
				<table>
					<tbody>
						<tr>
							<th>주문자 성함</th>
							<td><input type="text" placeholder="주문자 성함을 입력해주세요"></td>
						</tr>
						<tr>
							<th>주문번호</th>
							<td><input type="text" placeholder="주문번호를 입력해주세요"></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="bottom">
				<button class="payBtn"><router-link to="/completeOrder">주문 정보 확인</router-link></button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>

<style lang="scss">
#NonMemberCart {
	.boxwrap {width:100%; margin-top:50px;
		h6 {font-size:1rem; color:#000; font-weight: bold; width:100%; text-align: left; padding-bottom:20px;}
		table { margin-top:0; padding-top:0; width:100%;
			tr {
				&:nth-child(1) {border-top:1px solid #ccc}
				th {background:#f9f9f9; font-size:0.875rem; color:#111; width:150px; font-weight:500; padding:12px; vertical-align:middle;}
				td {width:calc(100% - 150px); padding:4px 16px;
					input {width:80%; border-radius:5px; border:1px solid #ccc;}
					h5 {font-size:1.5rem; color:#ff5746; font-weight:bold;}
				}
			}
		}
	}
	.bottom { width:100%; margin-top:81px; text-align: center;
		button.payBtn {width:276px; background:#8b0028; color:#fff; border-radius:10px; font-size:1.25rem; padding:18px 0;}
	}
}	
</style>