import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
import Intro from '@/views/Intro.vue'
import Organization from '@/views/Organization.vue'
import Board from '@/views/Board.vue'
import BoardDetail from '@/views/BoardDetail.vue'
import BoardWrite from '@/views/BoardWrite.vue'
import Partners from '@/views/Partners.vue'
import MyPage from '@/views/MyPage.vue'

//shop
import Shop from '@/views/Shop/Shop.vue'
import Shopping from '@/views/Shop/Shopping.vue'
import ShopDetail from '@/views/Shop/ShopDetail.vue'
import Cart from '@/views/Shop/Cart.vue'
import NonMemberCart from '@/views/Shop/NonMemberCart.vue'
import Payment from '@/views/Shop/Payment.vue'
import CompleteOrder from '@/views/Shop/CompleteOrder.vue'
import CheckDelivery from '@/views/Shop/CheckDelivery.vue'


Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Main',
		component: Main
	},
	{
		path: '/mypage',
		name: 'MyPage',
		component: MyPage
	},
	{
		path: '/intro',
		name: 'Intro',
		component: Intro
	},
	{
		path: '/organization',
		name: 'Organization',
		component: Organization
	},
	{
		path: '/board/:board',
		name: 'Board',
		component: Board,
	},
	{
		path: '/board/detail/:board/:id',
		name: 'BoardDetail',
		component: BoardDetail,
	},
	{
		path: '/board/:board/edit',
		name: 'BoardWrite',
		component: BoardWrite,
	},
	{
		path: '/partners',
		name: 'Partners',
		component: Partners
	},
	{
		path: '/shop',
		name: 'Shop',
		component: Shop,
		children: [
			{
				path: '/',
				component: Shopping,
				name: 'Shopping'
			},
			{
				path: 'detail/:id',
				component: ShopDetail,
				name: 'ShopDetail'
			},
		]
	},
	{
		path: '/cart',
		component: Cart,
		name: 'Cart'
	},
	{
		path: '/nonMemberCart',
		component: NonMemberCart,
		name: 'NonMemberCart'
	},
	{
		path: '/payment',
		name: 'Payment',
		component: Payment
	},
	{
		path: '/completeOrder',
		name: 'CompleteOrder',
		component: CompleteOrder
	},
	{
		path: '/delivery',
		name: 'CheckDelivery',
		component: CheckDelivery
	},
]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})

export default router
