<template>
	<div id="References" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>관련링크</h2>
			</div>
			<div class="row">
				<div class="col-3" v-for="list in referList" :key="list.title" @click="goLink(list)" >
					<a class="boxwrap">
						<div class="imgbox"><img :src="require('@/assets/image/references/'+list.image)"></div>
						<div class="txtbox">
							<h6 v-html="list.name"/>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			referList: [
				{
					image: 'logo-korea-univ.png',
					name: '고려대',
					link: 'https://www.korea.ac.kr/mbshome/mbs/university/index.do'
				} ,
				{
					image: 'logo-korea-univ-engineering.jpg',
					name: '고려대 공과대학',
					link: 'https://eng.korea.ac.kr/eng/index.do'
				},
				{
					image: 'logo-korea-univ-bio.png',
					name: '고려대 화공생명공학과',
					link: 'https://cbe.korea.ac.kr/wp/'
				},
					{
					image: 'logo-korea-univ-asso.png',
					name: '고려대 교우회',
					link: 'https://kuaa.or.kr/index.do'
				}

			]
		}
	},
	methods: {
		goLink(list) {
			if(list.link) {
				window.open(list.link, "toolbar=no, menubar=no, scrollbars=no, resizable=yes" );
			} else {
				window.open(this.$refs.images.src)
			}
		},
	}
}
</script>

<style lang="scss">
#References {
	.row { 
		.col-3 { margin-bottom:20px;
			.boxwrap {width:100%; border-radius:10px; box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.2); display:block; cursor: pointer; overflow: hidden;
				.imgbox {width:100%; height:103px; overflow:hidden; text-align:center;
					img {width:80%; object-fit:contain; height:inherit;}
				}
				.txtbox {width:100%; height:48px; background:#fafcfc; padding:16px 20px; text-align: center;
					h6 {font-size:0.875rem; padding-bottom:10px; font-weight:bold;}
					p {font-size:0.875rem; overflow:hidden; text-overflow:ellipsis; word-wrap:break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; line-height:1.5em; height:3em;}
				}
			}
		}
	}
}	
</style>