<template>
  <div id="editor-wrapper" class="editor" @click="editor.focus()">
    <label v-if="(value==='' || value === '<p></p>') && !focus" style="font-weight: 400; font-size : 14px; color : rgb(120,120,120);">내용을 입력해주세요.</label>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
// Import the editor
import { Editor, EditorContent} from 'tiptap'

export default {
  components: {
    EditorContent,
  },
  props : ["value"],
  data() {
    return {
      editor: null,
      emitAfterOnUpdate: false,
      focus : false
    }
  },
  mounted() {
    this.editor = new Editor({
      onUpdate: ({ getHTML }) => {
        this.emitAfterOnUpdate = true;
        this.$emit("input", getHTML());
        if(getHTML() === '<p></p>') {
          this.editor.setContent('')
        }
      },
      onFocus : () => {
        this.focus = true;
      },
      onBlur : () => {
        this.focus = false;
      },
      content: this.value,
    })
  },
  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  },
  watch: {
    value(val) {
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false
        return
      }
      if (this.editor) this.editor.setContent(val)
    }
  }
}
</script>

<style lang="scss">
#editor-wrapper {
  width: 100%;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px;
  // font-size: 0.875rem;
  height: 40vh;
  overflow-y: scroll;
}
.editor {
  font-family: MalgunGothic;
  p {
    font-weight: 400 !important;
  }
}
</style>