<template>
	<div id="app">
		<Header ref="header"/>
		<router-view @signin="signin"/>
		<Footer/>
	</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from '@/components/Footer'

export default {
	created() {
	},
	mounted() {

	},
	name: 'App',
	components: {
		Header,
		Footer
	},
	methods: {
		signin() {
			this.$refs['header'].$refs['login'].show()
		}
	}
}
</script>

<style>

</style>


