<template>
	<div id="Main">
		<div class="wrapper">
			<div id="visual">
				<img src="@/assets/image/home-background-img@3x.png">
				<span>사진출처: 고파스 류랑님</span>
			</div>
			<div id="shop">
				<div class="titlebox">
					<h2>화통회샵</h2>
				</div>
				<div class="row">
					<div class="col-4" v-for="(item,index) in list" :key="index">
						<router-link :to="'/shop/detail/'+item.id" class="boxwrap">
							<div class="imgbox"><img :src="item.thumbnail"></div>
							<div class="txtbox">
								<p v-html="item.name"></p>
								<h6>{{changeCommaPrice(item.price)}}원</h6>
							</div>
						</router-link>
					</div>
				</div>
			</div>
			<div id="board">
				<div class="row">
					<div class="col-6" v-for="list in boardMenu" :key="list.title">
						<div class="titlebox">
							<h3 v-html="list.name"></h3>
							<router-link :to="'/board/'+list.path">더보기</router-link>
						</div>
						<ul class="board-list" v-if="list.path != 'gallery'">
							<li v-for="(item,index) in boards[list.path]" :key="index">
								<router-link :to="'/board/detail/' + list.path + '/' + item.id">{{item.title}}</router-link>
							</li>
						</ul>
						<ul class="gallery-list" v-else>
							<li v-for="(item, index) in boards.gallery" :key="index">
								<router-link :to="'/board/detail/gallery/' + item.id">
									<div class="imgbox"><img :src="item.thumb ? item.thumb : require('@/assets/image/favicon-logo@3x.png')"></div>
									<div class="txtbox">
										<p v-html="item.title"></p>
										<span>{{formatDate(item.create_datetime)}} | {{item.author}}</span>
									</div>
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div style="margin-bottom: -50px;"><Partners></Partners></div>
		<References></References>
	</div>


</template>
<script>
import store from '@/assets/js/store.js'
import board from '@/api/board'	
import Partners from '@/views/Partners.vue'
import References from './References.vue'

export default {
	components: {
		Partners, References
	},
	mixins: [store],
	async created() {
		for(let item of this.boardMenu) {
			await this.loadBoardList(item.path)
		}
		this.loadProductList();
	},
	data() {
		return {	
			substance: 'all',
			search: '',
			boards: {
				free: [],
				gallery: [],
				// alert: [],
				newsletter: [],
				// notice: [],
				attch: [],
			},
			list : [{
				id : '',
				name : '',
				price : '',
				thumbnail : ''
			}]
		}
	},
	methods: {
		async loadBoardList(category) {
			let response = await board.getList(category, 'all', this.substance, this.search, 0, 10000)
			this.boards[category] = response.data.data.list;

			console.log(this.boards)
		},
		formatDate(date) {
			if (date) {
				var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear()
				if (month.length < 2) {
					month = '0' + month
				}
				if (day.length < 2) {
					day = '0' + day
				}
				return [year, month, day].join('-')
			} else {
				return null
			}
		},
		async loadProductList() {
			const result = await board.getProductList();

			this.list = result.data.list;
		}
	}
}

</script>

<style lang="scss">
#Main {
	#visual {width:100%; margin-top:30px; height:500px; 
		img {height:inherit; object-fit:cover;}
		span {display: block; text-align: right; font-size:0.75rem; color:rgba(0, 0, 0, 0.6); margin-top:8px;}
	}
	#shop {margin-top:60px;
		.boxwrap {width:100%;
			.imgbox {width:100%; height:340px; overflow:hidden; border:1px solid #eee;
				img {height:inherit; object-fit:cover;}
			}
			.txtbox {padding-top:25px;
				p {font-size:1.125rem; color:rgba(0,0,0,0.6); padding-bottom:10px;}
				h6 {font-size:1.25rem; color:#000; font-weight:bold;}
			}
		}
	}
	#board { margin-top:96px; margin-bottom:1rem;
		.titlebox { display: flex; height: auto; align-items: center; padding-bottom:12px; margin-bottom:0;
			a {display: block; padding:8px; font-size:0.75rem; color:rgba(0,0,0,0.87); background:#fafcfc; border-radius:4px; margin-left:auto;}
		}
		ul { margin-bottom:44px;
			&.board-list {
				li {border-bottom:1px solid #eee; padding:1rem 0; font-size:0.875rem; cursor:pointer; display: none;
					a {
						width:inherit;
						height: inherit;
						display: inherit;
					}
					&:first-child {
						display: block;
					}
					&:nth-child(2) {
						display: block;
					}
					&:nth-child(3) {
						display: block;
					}
					&:nth-child(4) {
						display: block;
					}
				}
			}
			&.gallery-list { display: flex;
				li {width:248px; display: none;
					a {
						width:inherit;
						height: inherit;
						display: inherit;
					}
					&:nth-child(1) {
						display: block;
					}
					&:nth-child(2) {margin-left:auto; display: inline-block;}
					.imgbox {margin-top:20px; width:100%; height:160px; overflow:hidden; border:1px solid #eee;
						img {height:inherit; object-fit:cover;}
					}
					.txtbox {padding-top:14px;
						p {font-weight:bold; font-size:0.875rem; line-height:1.4; padding-bottom:10px;}
						span {display:block; font-size:0.875rem; color:rgba(0,0,0,0.6);}
					}
				}
			}
		}
	}
}	
</style>