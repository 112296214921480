<template>
	<div id="ShopDetail" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>제품 상세</h2>
			</div>
			<div class="intro">
				<carousel :per-page="1" :mouse-drag="true">
				    <slide>
				    	<img :src="productData.thumbnail">
				    </slide>
				</carousel>
				<div class="introTitle">
					<h1 v-html="shopDetail.name"></h1>
					<h4>{{changeCommaPrice(productData.price)}}</h4>
					<b-button-group>
						<!-- <b-button><router-link to="/">장바구니</router-link></b-button> -->
						<b-button @click="goToCart()">바로 구매</b-button>
					</b-button-group>
				</div>
			</div>
			<div class="txtbox">
				<div class="txt">
					<img :src="productData.description">
				</div>
			</div>
			<button class="cartBtn" @click="goToCart()"><img src="@/assets/image/cart@3x.png"></button>
		</div>
	</div>
</template>

<script>
import api from '@/api/board.js'
import store from '@/assets/js/store.js'
import cart from '@/assets/js/cart.js'
import { Carousel } from 'vue-carousel';

export default {
	created() {
		this.loadProductData();
	},
	mixins: [store, cart],
	components: {
	    Carousel,
	},
	data() {
		return {
			data: [
		        '<div class="example-slide">Slide 1</div>',
		        '<div class="example-slide">Slide 2</div>',
		        '<div class="example-slide">Slide 3</div>',
		    ],
			slideList: [
				{ image: 'item-thumnail@3x.png' },
				{ image: 'item-thumnail@3x.png' },
			],
			shopDetail: {
				name: '고대 교우캐릭터 골프공 컬러 12구set',
				price: 45000,
			},
			productData : {
				id : '',
				name : '',
				price : 0,
				thumbnail : '',
				description : ''
			}
		}
	},
	methods: {
		async loadProductData() {
			const result = await api.getProductDetail(this.$route.params.id);
			Object.keys(this.productData).map(key => {
				this.productData[key] = result.data.data[key];
			})

			console.log(this.productData)
		},
		goToCart() {
			this.addCart(this.productData.id);
			this.$router.history.push('/cart')
		}
	}
}
</script>

<style lang="scss">
#ShopDetail {
	.wrapper {position:relative; text-align:right;
		.titlebox {text-align:left;} 
		.intro { width:600px; margin:30px auto 0; text-align:left; 
			.carousel {
				.carousel-inner {overflow:initial;}
				img {width:100%; border:1px solid #eee;}
				ol { bottom: -4rem;
					li {width:15px; height:16px; border-radius:10px; border:1px solid #ccc; box-sizing:border-box; background:#fff; margin: 0 7px;
						&.active {background:#d8d8d8;}
					}
				}
			}
			.VueCarousel {
				img {width:100%; border:1px solid #eee;}
			}
			.introTitle { padding-top:60px; padding-bottom:60px;
				h1 {font-size:2.25rem; color:000; font-weight:800;}
				h4 {font-size:1.875rem; color:#8b0028; font-weight:bold;}
			}
		}
		.txtbox {width:600px; margin:0 auto;
			.txt {}
		}
		.btn-group { width:600px; font-size:1.25rem; text-align: center; margin-top:40px;
			button { width:50%; font-weight:bold; padding:1rem 0; border:1px solid #8b0028; 
				&:nth-child(1) {border-radius:10px 10px 10px 10px; color:#fff; background:#8b0028;}
				&:nth-child(2) {border-radius:0 10px 10px 0; color:#fff; background:#8b0028;}
			}
		}
		.cartBtn {width:100px; height:100px; position:sticky; bottom:60px; right:0; 
			img {width:100%; box-shadow:0 2px 4px 0 rgba(0,0,0,0.5); border-radius:50px;;}
		}
	}
}
</style>