<template>
	<div id="BoardWrite" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>{{matchBoard()}}
					<span v-text="$route.query.edit == 'edit' ? '수정' : '글쓰기'">수정</span>
				</h2>
			</div>
			<div class="content">
				<table>
					<tbody>
						<!-- <tr>
							<th>작성자</th>
							<td><input type="text" placeholder="작성자 이름" v-model="author"></td>
						</tr> -->
						<tr>
							<th>제목</th>
							<td>
								<input type="text" placeholder="게시글 제목" v-model="title">
								<b-form-checkbox v-if="admin" v-model="noticeCheck">공지로 등록하기</b-form-checkbox>
							</td>
						</tr>
						<tr>
							<th>내용</th>
							<td>
								<Editor v-model="content"></Editor>
							</td>
						</tr>
						<tr>
							<th>파일첨부</th>
							<td>
								<div class="d-flex">
									<file-upload
										class="btn"
										post-action="/upload/post"
										:multiple="true"
										:drop="true"
										:drop-directory="true"
										v-model="files"
										ref="upload">
									  파일 선택
									</file-upload>
									<button type="button" class="btn" @click="deleteFile()">모두 삭제</button>
								</div>
								<div class="example-drag">
									<div class="upload">
										<ul>
											<li v-for="(file) in attachment" :key="file.id"  v-if="attachment.length && !file.isDelete">
											  <span class="deleteBtnSmall" @click.prevent="removeAttach(file)"><img src="@/assets/image/file-icon-delete@3x.png"></span> <!-- 삭제 버튼 -->
											  <img src="@/assets/image/file-icon@3x.png">
											  <span>{{file.filename}}</span> 
											  <span v-if="file.error">{{file.error}}</span>
											  <span v-else-if="file.success">success</span>
											  <span v-else-if="file.active">active</span>
											  <span v-else></span>
											</li>
											<li v-for="(file) in files" :key="file.id" v-if="files.length && !file.isDelete">
												<span class="deleteBtnSmall" @click.prevent="removeFile(file)"><img src="@/assets/image/file-icon-delete@3x.png"></span>
												<img src="@/assets/image/file-icon@3x.png">
												<span>{{file.name}}</span> 
												<span v-if="file.error">{{file.error}}</span>
												<span v-else-if="file.success">success</span>
												<span v-else-if="file.active">active</span>
												<span v-else></span>
											</li>
											<li v-else-if="attachment.length == 0 && files.length == 0">여기에 첨부파일을 끌어 오세요</li>
											<li v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">여기에 첨부파일을 끌어 오세요</li>
										</ul>
									</div>
								</div>
								<button class="editBtn" @click="update()">
									<span v-html="$route.query.edit ? '수정완료' : '글 올리기'" :disabled="title.length == 0 || content.length == 0">수정완료</span>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<b-modal id="galleryNoImageModal" ref="galleryNoImageModal" hide-header hide-footer centered>
				<div class="modal-body boardModal">
					<h5>갤러리 글쓰기</h5>
					<p>이미지를 하나 이상 첨부해주세요</p>
					<div class="bottom">
						<button @click="$refs['galleryNoImageModal'].hide()">확인</button>
					</div>
				</div>
			</b-modal>
		</div>
	</div>
</template>

<script>
import store from "@/assets/js/store.js"
import FileUpload from 'vue-upload-component'
import board from '@/api/board'
import Editor from '@/components/html-editor.vue';

export default {
	mixins: [store],
	components: {
		FileUpload,
		Editor
	},
	created() {
		this.admin = this.$root.isLogin;

		if(this.$route.query.edit) {
			this.loadDetail(this.$route.query.id)
		}
	},
	data() {
		return {
			admin: true,
			noticeCheck: false,
			boardPw: null,
			confirmPw: '1234',
			showBoardModal: true,
			files: [],
			attachment: [],
			author: '',
			title : '',
			content : '',
			password: ''
		}
	},
	methods: {
		cancelEdit() {
			history.go(-1);
		},
		inputFile: function (newFile, oldFile) {
		  if (newFile && oldFile && !newFile.active && oldFile.active) {
			// Get response data
			console.log('response', newFile.response)
			if (newFile.xhr) {
			  //  Get the response status code
			  console.log('status', newFile.xhr.status)
			}
		  }
		},
		inputFilter: function (newFile, oldFile, prevent) {
		  if (newFile && !oldFile) {
			// Filter non-image file
			if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
			  return prevent()
			}
		  }

		  // Create a blob field
		  newFile.blob = ''
		  let URL = window.URL || window.webkitURL
		  if (URL && URL.createObjectURL) {
			newFile.blob = URL.createObjectURL(newFile.file)
		  }
		},
		async loadDetail(id) {
			const response = await board.getDetail(id);

			this.author = response.data.data.author;
			this.title = response.data.data.title;
			this.is_notice = response.data.data.is_notice;
			this.attachment = response.data.data.attachment;

			console.log(this.attachment)

			this.content = response.data.data.content.replace(/<br\/>/g, '\n');
		},
		deleteFile() {
			this.files = []
			this.attachment = []
		},
		removeFile(file) {
			this.$refs.upload.remove(file)
		},
		removeAttach(file) {
			file.isDelete = true;
			this.$forceUpdate();
		},
		async write() {
			const formData = new FormData();
			// formData.append('excelFile',this.file)

			formData.append('is_notice', this.noticeCheck ? 'Y' : "N")
			formData.append('author', this.author ? this.author : '관리자');
			formData.append('title', this.title);
			formData.append('content', this.content.replace(/\n/g, '<br/>'));

			this.files.map(file => {
				formData.append("attachment", file.file);
			})

			const res = await board.write(this.$route.params.board, formData);

			alert("게시글이 등록되었습니다.");

			this.$router.push('/board/detail/' + this.$route.params.board + '/'+ res.data.data.id)
		},
		async update() {
			const formData = new FormData();
			// formData.append('excelFile',this.file)

			formData.append('is_notice', this.noticeCheck ? 'Y' : "N");
			formData.append('author', this.author);
			formData.append('title', this.title);
			formData.append('content', this.content.replace(/\n/g, '<br/>'));

			if(this.$route.query.edit) {
				this.files.map(file => {
					formData.append("attachment", file.file);
				})

				this.attachment.map(item => {
					if(item.isDelete) {
						formData.append("deleteAttachment", item.id);
					}
				})

				const response = await board.update(this.$route.query.id, formData);

				alert("게시글이 수정되었습니다.");

				this.$router.push('/board/detail/' + this.$route.params.board + '/' + response.data.data.id)
			} else {
				this.files.map(file => {
					formData.append("attachment", file.file);
				})

				const res = await board.write(this.$route.params.board, formData);

				alert("게시글이 등록되었습니다.");

				this.$router.push('/board/detail/' + this.$route.params.board + '/'+ res.data.data.id)
			}
		},
	}
}
</script>

<style lang="scss">
#BoardWrite {
	.content { margin-top:30px;
		table {width:100%;
			th {font-size:1.125rem; color:#111; min-width:100px; vertical-align:top; padding-top:10px; max-width:100px; width:100px;}
			td {padding:7px 0; width:calc(100% - 100px);
				input[type="text"] {width:50%; padding:6px 10px; border:1px solid #c7c7c7; border-radius:4px; display:inline-block; }
				input[type="password"] {width:50%; padding:6px 10px; border:1px solid #c7c7c7; border-radius:4px; display:inline-block; }
				.custom-checkbox {display:inline-block; margin-left:15px;
					label {font-size:0.875rem; color:#000; font-weight:500; }
				}
				input[type="checkbox"] { position:absolute; width:1px; height:1px; padding:0; margin:-1px; overflow:hidden; clip:rect(0,0,0,0); border:0; 
					+ label {display:inline-block; position:relative; padding-left:30px; cursor:pointer; font-size:0.875rem;
						&:before {content:''; position:absolute; left:0; top:0; width:20px; height:20px; text-align:center; background:#fff; border:0; box-sizing:border-box; background-image:url("../assets/image/small-check-unchecked@3x.png"); background-repeat:no-repeat; background-size:20px;}
					}
					&:checked + label:after{ content: ''; position:absolute; top:0; left:0; width:20px; height:20px; background-color:#8b0028; background-image:url("../assets/image/small-check-checked@3x.png"); background-repeat:no-repeat; background-size:20px;}
				}
				textarea {width:100%; padding:6px 10px; border:1px solid #c7c7c7; border-radius:4px; display:inline-block; height:400px; overflow-x: auto;}
				.btnwrap { margin-bottom:14px; 
					button {width:80px; border:1px solid #c7c7c7; border-radius:4px; font-size:0.875rem; line-height:32px;  padding:0; color:#000; margin-left:10px;}
					label {width:80px; border:1px solid #c7c7c7; border-radius:4px; font-size:0.875rem; line-height:32px;  padding:0; text-align:center; color:#000; }
				}
				ul {width:100%; height:120px; overflow-x:auto; border:1px dashed #c7c7c7; padding:5px 0;
					li {display:flex; height:auto; align-items:center; padding:5px 16px;
						button {width:24px; height:24px; margin-right:5px; padding:0;
							img {width:100%; height:inherit;}
						}
						img {width:20px; height:20px; }
						p {font-size:0.875rem; color:#000; font-weight:500;}
					}
				}
				button.editBtn {width:550px; height:60px; border-radius: 5px; background:#8b0028; color:#fff; font-size:1.125rem; margin-top:30px;}

				.btn {
					width:80px; 
					border:1px solid #c7c7c7; 
					border-radius:4px; 
					font-size:0.875rem; 
					line-height:32px;  
					padding:0; 
					text-align:center; 
					color:#000;
					margin-right: 10px;
					margin-bottom: 10px;
				}
			}
		}
	}
	#boardPwBox {width:460px; margin:150px auto 0; border:3px solid #8b0028; border-radius:10px; padding:60px 30px; 
		h4 {font-size:1.25rem; color:#000; width:100%; text-align:center; padding-bottom:30px; font-weight:bold;}
		input {width:100%; border:1px solid #eee; height:40px; padding:10px;}
		.btnwrap {width:100%; text-align:center; margin-top:30px;
			button {font-size:0.875rem; color:#fff; font-weight:500; width:70px; height:32px; border-radius:4px; margin:0 5px;
				&.cancelBtn {background:#3b3f4d;}
				&.registBtn {background:#8b0028;}
			}
		}
	}
}

.example-drag {
	ul {
		padding: 20px;
	}
}

.boardModal {
	h5 {font-size:1.125rem; font-weight:bold; }
	p {font-size:0.875rem; color:#000; font-weight:500; padding:25px 0;}
	.bottom {width:100%; text-align:right;
		button {width:88px; height:26px; border-radius:5px; color:#fff; font-weight:500; text-align:center; background:#8b0028; font-size:0.75rem}
	}
	.btnwrap {width:100%; text-align:right;
		button {width:88px; height:26px; border-radius:5px; color:#fff; font-weight:500; text-align:center; background:#8b0028; font-size:0.75rem; margin-left:10px;
			&.cancelBtn {background:#3b3f4d;}
		}
	}
}	
</style>