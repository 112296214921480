<template>
	<div id="MyPage" class="container">
		<div class="wrapper">
			<div class="titlebox">
				<h2>마이페이지</h2>
			</div>
			<div class="boxwrap">
				<h6>사용자 정보</h6>
				<table class="horizational">
					<tbody>
						<tr>
							<th>학번</th>
							<td>{{no}}</td>
						</tr>
						<tr>
							<th>이름</th>
							<td>{{name}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="boxwrap">
				<h6>최근작성글</h6>
				<table class="table">
					<thead>
						<tr>
							<th>게시판</th>
							<th>게시글제목</th>
							<th>날짜</th>
						</tr>
					</thead>
					<tbody>
						<!-- <tr>
							<td>자유게시판</td>
							<td>게시글제목입니다</td>
							<td>2020-10-05</td>
						</tr> -->
					</tbody>
				</table>
			</div>
			<div class="boxwrap">
				<h6>최근 주문 상품</h6>
				<div class="no-data">자료 없음</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from '@/api/board';

export default {
	created() {
		this.getMyInfo();
	},
	data () {
		return {
			name : '',
			no : '',
			boardList : [],
			orderList : []
		}
	},
	methods : {
		async getMyInfo() {
			const result = await api.getMy();
			this.name = result.data.data.login_id.split("_")[1];
			this.no = result.data.data.login_id.split("_")[0];

			console.log(result);
		}
	}
}
</script>

<style lang="scss">
#MyPage {
	.boxwrap {
		width:100%;
		margin-top: 50px;
		h6 {
			font-size: 1rem;
			color: #000;
			font-weight: bold;
			width: 100%;
			text-align: left;
			padding-bottom: 20px;
		}
		table {
			margin-top: 0;
			padding-top: 0;
			width: 100%;
			&.horizational {
				tbody {
					tr {
						&:nth-child(1) {
							border-top: 1px solid #ccc;
						}
						&:last-child {
							border-bottom: 1px solid #ccc;
							th {
								border-top: 1px solid #eee;
							}
							td {
								border-top: 1px solid #eee;
							}
						}
						th {
							background-color: #f9f9f9;
							font-size: 0.875rem;
							color: #111;
							width: 150px;
							font-weight: 500;
							padding: 12px;
							vertical-align: middle;
						}
						td {
							width: calc(100% - 150px);
							padding: 4px 16px;
							font-size: 0.875rem;
						}
					}
				}
			}
			&.table {
				thead {
					tr {
						border-top: 1px solid #8b0028;
    					border-bottom: 1px solid #8b0028;
					}
					th {
						border-bottom: 1px solid #8b0028;
						padding: 10px;
						&:nth-child(1) {
							width: 20%;
						}
						&:nth-child(2) {
							text-align: left;
							width: 60%;
						}
					}
				}
				tbody {
					tr {
						border-color: #ccc;
						td {
							padding: 15px 10px;
							&:nth-child(1) {
								width: 20%;
								vertical-align: middle;
							}
							&:nth-child(2) {
								text-align: left;
								width: 60%;
							}
						}
					}
				}
			}
		}
		.no-data {
			width:100%;
			height: 60px;
			display: flex;
			align-items: center;
			padding: 20px;
			background-color: #f9f9f9;
		}
	}
}
</style>