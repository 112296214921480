import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueDaumPostcode from "vue-daum-postcode"

import router from './router'

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueCarousel from 'vue-carousel';

import "@/assets/css/common.scss";
import "@/assets/css/only-ie.scss";

Vue.use(BootstrapVue)
Vue.use(VueCarousel);

Vue.use(VueDaumPostcode)

const host = "https://service.hwatong.or.kr/"

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent);

Vue.prototype.ajax = axios.create({
	baseURL: host,
	headers : {
				"Authorization" : getCookie('session'),
		},
	timeout: 30000
});

window.fileDownload =  function (url, filename) {
  try {
  	axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
    }).then(res => {
    	var blob = new Blob([res.data], {type: res.data.type});
	    var fileName = filename;
	    fileName = decodeURI(fileName);

	    if (window.navigator.msSaveOrOpenBlob) { // IE 10+
	      window.navigator.msSaveOrOpenBlob(blob, fileName);
	    } else { // not IE
	      var link = document.createElement('a');
	      var url = window.URL.createObjectURL(blob);
	      link.href = url;
	      link.target = '_self';
	      if (fileName) link.download = fileName;
	      document.body.append(link);
	      link.click();
	      link.remove();
	      window.URL.revokeObjectURL(url);
	    }
    })
  } catch (e) {
    console.error(e)
  }
}

Array.prototype.remove = function(from, to) {
  var rest = this.slice((to || from) + 1 || this.length);
  this.length = from < 0 ? this.length + from : from;
  return this.push.apply(this, rest);
}

function getCookie(name) {
		var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
		return value? value[2] : null;
}

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App),
	data() {
  	return {
	  		isLogin : false
	  	}
	}
}).$mount('#app')

