<template>
	<div id="Shop">
		<router-view></router-view>
	</div>
</template>

<script>

export default {
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>

<style lang="scss">
</style>