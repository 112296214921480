<template>
	<div id="CheckDelivery" class="container">
		<div class="wrapper">
			<h2>주문내역</h2>
			<div class="boxwrap">
				<h6>주문정보</h6>
				<table>
					<tbody>
						<tr>
							<th>주문번호</th>
							<td>{{orderInfo.ordernum}}</td>
						</tr>
						<tr>
							<th>수령인</th>
							<td>{{orderInfo.name}}</td>
						</tr>
						<tr>
							<th>연락처</th>
							<td>{{orderInfo.phone}}</td>
						</tr>
						<tr>
							<th>주문상태</th>
							<td>{{orderInfo.status}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="boxwrap">
				<h6>상세내역조회</h6>
				<table>
					<tbody>
						<tr>
							<th>결제자</th>
							<td>홍길동</td>
						</tr>
						<tr>
							<th>결제방법</th>
							<td>카드</td>
						</tr>
						<tr>
							<th>결제금액</th>
							<td>12,345원</td>
						</tr>
						<tr>
							<th>배송지</th>
							<td>서울시 성동구 뚝섬로 1길 10 트러스톤빌딩 4층</td>
						</tr>
						<tr>
							<th>배송 시 요청사항</th>
							<td>안전배송 부탁드려요</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="btnwrap">
				<button @click="goHome">홈으로 돌아가기</button>
			</div>
		</div>
	</div>
</template>

<script>
import store from '@/assets/js/store.js'

export default {
	mixins: [store],
	methods: {
		goHome() {
			this.$router.push('/')
		}
	}
}
</script>

<style lang="scss">
#CheckDelivery {
	.boxwrap {
		width: 100%;
		margin-top: 50px;
		h6 {
			font-size: 1rem;
			color: #000;
			font-weight: bold;
			width:100%;
			text-align: left;
			padding-bottom: 20px;
		}
		table {
			margin-top: 0;
			padding-top: 0;
			width: 100%;
			tr {
				&:nth-child(1) {
					border-top:1px solid #ccc;
					td {
						border-top:1px solid #ccc;
					}
				}
				&:last-child {
					th {
						border-bottom:1px solid #ccc;
					}
					td {
						border-bottom:1px solid #ccc;
					}
				}
				th {
					background-color: #f9f9f9;
					font-size: 0.875rem;
					color: #111;
					width: 150px;
					font-weight: 500;
					padding: 12px;
					vertical-align: middle;
					border-bottom: 1px solid #eee;
				}
				td {
					width: calc(100% - 150px);
					padding: 4px 16px;
					font-size: 0.875rem;
					border-bottom: 1px solid #eee;
				}
			}
		}
	}
	.btnwrap {
		margin: 0 auto;
		text-align: center;
	}
	button {
		width: 200px;
		font-size: 1.25rem;
		color: #fff;
		height: 60px;
		border-radius: 8px;
		background-color: #8b0028;
		margin-top: 80px;
	}
}
</style>