<template>
	<div id="CompleteOrder" class="container">
		<div class="wrapper">
			<div class="top">
				<div class="imgbox"><img src="@/assets/image/checkout-complete@3x.png"></div>
				<h1>주문이 완료되었습니다.</h1>
				<!-- <p>{{phone}}으로 주문번호를 보내드렸습니다.</p> -->
				<button>화통회 샵으로 가기</button>
			</div>
			<div class="content">
				<h4>주문내역</h4>
				<div class="boxwrap">
					<h6>주문자</h6>
					<table>
						<tbody>
							<tr>
								<th>이름</th>
								<td>{{data.ordererName}}</td>
							</tr>
							<tr>
								<th>연락처</th>
								<td>{{data.ordererPhone}}</td>
							</tr>
							<tr>
								<th>이메일</th>
								<td>{{data.ordererEmail}}</td>
							</tr>
						</tbody>
					</table>
					<!-- <p class="notice">해당 이메일로 영수증과 주문내역서를 추가로 보내드립니다.</p> -->
				</div>
			</div>
			<div class="boxwrap">
				<h6>배송정보</h6>
				<table>
					<tbody>
						<tr>
							<th>수령인</th>
							<td>{{data.deliveryInfoName}}</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>{{data.deliveryInfoAddress}} {{data.deliveryInfoAddressDetail}}</td>
						</tr>
						<tr>
							<th>배송요청 사항</th>
							<td>{{data.deliveryInfoComment}}</td>
						</tr>
						<tr>
							<th>연락처</th>
							<td>{{data.deliveryInfoPhone}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- <div class="boxwrap">
				<h6>주문상품</h6>
				<ul>
					<li v-for="list in paymentList" :key="list._id">
						<div class="imgbox"><img :src="require('@/assets/image/'+list.image)"></div>
						<div class="txtbox">
							<b>{{list.name}}</b>
							<p>옵션: {{list.option}} / 수량: {{list.count}}</p>
							<span>{{list.price}}원</span>
						</div>
					</li>
				</ul>
			</div> -->
			<div class="boxwrap">
				<h6>주문금액</h6>
				<table>
					<tbody>
						<tr>
							<th>상품금액</th>
							<td>{{changeCommaPrice(data.totalPrice)}}원</td>
						</tr>
						<!-- <tr>
							<th>배송비</th>
							<td>{{changeCommaPrice(2500)}}원</td>
						</tr>						
						<tr>
							<th><b>총 금액</b></th>
							<td><h5>{{changeCommaPrice(sum())}}원</h5></td>
						</tr> -->
					</tbody>
				</table>
			</div>
			<div class="boxwrap">
				<h6>고객센터</h6>
				<div class="row">
					<div class="col-4">
						<div class="box">
							<b>대표전화</b>
							<h5>070-4457-4146</h5>
							<p>평일 10:00~ 18:00 (점심 12:00~13:00)</p>
						</div>
					</div>
					<div class="col-4">
						<div class="box">
							<b>대표이메일</b>
							<h5>001204@smartjackwp.com</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from '@/assets/js/store.js'
import api from '@/api/board.js'

export default {
	mixins: [store],
	async created() {
		if(self.opener) {
			self.opener.window.moveToComplete(location.href)
			window.close();
		} else {
			console.log(this.$route.query)
			console.log(await api.getOrderInfo(this.$route.query.orderId))
		}
	},
	data() {
		return {
			data : {
			   "orderId":"KORUNI_1666776036105",
			   "status":"reserve",
			   "ordererName":"정동주",
			   "ordererPhone":"01090445780",
			   "ordererEmail":"ll@ll.ll",
			   "deliveryInfoName":"정동주",
			   "deliveryInfoPostcode":"02044",
			   "deliveryInfoAddress":"서울 중랑구 봉화산로 153",
			   "deliveryInfoAddressDetail":"",
			   "deliveryInfoComment":"",
			   "deliveryInfoPhone":"01090445780",
			   "inipayTimestamp":"1666776036270",
			   "inipaySignature":"b6dc37d2b0929de8957371d38a7bad3dac82ba5240932",
			   "price":-1500,
			   "count":1,
			   "deliveryPrice":2500,
			   "totalPrice":1000,
			   "create_datetime":"2022-10-26T18:20:36.000Z"
			}
		}
	},
	methods: {

	}
}
</script>

<style lang="scss">
#CompleteOrder {
	.top { width:100%; text-align: center; margin-bottom:100px;
		.imgbox {width:240px; margin:0 auto;
			img {width:100%; margin-left:40px;}
		}
		h1 {font-size:2rem; color:#000; font-weight:bold; padding-top:30px; padding-bottom:20px;}
		p {font-size:1rem; font-weight:500;}
		button {width:276px; background:#8b0028; color:#fff; border-radius:10px; font-size:1.25rem; padding:18px 0; margin-top:54px;}
	}
	h4 {font-size:1.5rem; color:#000; font-weight:800; padding-bottom:20px; width:100%; border-bottom:1px solid #8b0028;}
	.boxwrap {width:100%; margin-top:50px;
		&:nth-child(4) {
			h6 {border-bottom:1px solid #bbb;}
			table {
				tr {
					&:nth-child(1) {border-top:0;}
				}
			}
		}
		&:nth-child(6) {
			h6 {border-bottom:1px solid #bbb;}
		}
		h6 {font-size:1rem; color:#000; font-weight: bold; width:100%; text-align: left; padding-bottom:20px;}
		table { margin-top:0; padding-top:0; width:100%;
			tr {
				&:nth-child(1) {border-top:1px solid #ccc;
					td {
						border-top:1px solid #ccc;
					}
				}
				&:last-child {
					th {
						border-bottom:1px solid #ccc;
					}
					td {
						border-bottom:1px solid #ccc;
					}
				}
				th {background:#f9f9f9; font-size:0.875rem; color:#111; width:150px; font-weight:500; padding:12px; vertical-align:middle; border-bottom: 1px solid #eee;}
				td {width:calc(100% - 150px); padding:4px 16px; border-bottom: 1px solid #eee;
					input {width:100%; border-radius:5px; border:1px solid #ccc; }
					h5 {font-size:1.5rem; color:#ff5746; font-weight:bold;}
				}
			}
		}
		ul {
			li {width:100%; padding:20px; display:flex; height: auto; align-items: center;
				.imgbox {width:120px; height:120px; border:1px solid #eee; overflow:hidden;
					img {width:inherit; height: inherit; object-fit: cover;}
				}
				.txtbox { padding-left:20px;
					b {font-size:0.875rem; font-weight: 500;}
					p {margin-top:6px; font-weight:500; font-size:0.875rem}
					span {display: block; font-size: 0.875rem;}
				}
			}
		}
		p.notice {margin-top:14px; font-size:0.75rem;}
	}
	.row { margin-top:20px;
		.col-4 { 
			.box {width:100%; height:135px; border-radius:5px; background: rgba(139,0,40,0.1); padding:20px;
				b {font-size:0.875rem; font-weight:bold;}
				h5 {font-size:1.5rem; font-weight:800; padding-top:14px; padding-bottom:7px;}
				p {display:block; font-size:0.75rem; font-weight:400;}
			}
		}
	}
}	
</style>